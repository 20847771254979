import { FC, useState } from "react";
import {
  ActivityIndicator,
  Button,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { AiMarkdown } from "../components/AiMarkdown";
import { KeyboardAvoidingView } from "../components/KeyboardAvoidingView";
import { useGoogleSearch } from "../hooks/Docs";
import { useTheme } from "../providers/Theme";

export const GoogleSearchScreen: FC = () => {
  const theme = useTheme();
  const { mutate, isLoading, data, error } = useGoogleSearch();
  const [search, setSearch] = useState("");
  const go = () => {
    if (isLoading) return;
    if (!search.trim()) return;
    mutate(search.trim());
  };
  return (
    <KeyboardAvoidingView style={{ height: "100%" }}>
      <ScrollView
        keyboardDismissMode="on-drag"
        contentContainerStyle={[
          { padding: theme.spacing.m, gap: theme.spacing.m },
        ]}
      >
        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Search
        </Text>
        <TextInput
          autoFocus
          placeholder={"What is the weather in New York?"}
          placeholderTextColor={theme.colors.onSurface + "80"}
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            color: theme.colors.onSurface,
            opacity: isLoading ? 0.5 : 1,
          }}
          value={search}
          onChangeText={isLoading ? undefined : setSearch}
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === "Enter") go();
          }}
        />
        <Button
          color={theme.colors.primary}
          title="Go"
          onPress={() => mutate(search)}
          disabled={isLoading || !search.trim()}
        />
        {!!error && (
          <Text
            style={[
              theme.typography.subtitle2,
              {
                color: theme.colors.onError,
                backgroundColor: theme.colors.error,
                padding: theme.spacing.m,
                borderRadius: theme.border.radius.s,
              },
            ]}
          >
            {String(error)}
          </Text>
        )}
        {isLoading && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: theme.spacing.m,
              padding: theme.spacing.m,
              borderRadius: theme.border.radius.s,
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
              backgroundColor: theme.colors.surface,
            }}
          >
            <ActivityIndicator
              style={{ flex: 1 }}
              color={theme.colors.primary}
            />
          </View>
        )}
        {data && (
          <AiMarkdown
            style={{
              backgroundColor: theme.colors.surface,
              paddingVertical: theme.spacing.s,
              paddingHorizontal: theme.spacing.m,
              borderRadius: theme.border.radius.s,
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
            }}
            markdown={data}
            textColor={theme.colors.onSurface}
          />
        )}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};
