import { useEffect, useMemo, useState } from "react";

export function usePromise<T>(p?: Promise<T>) {
  const [value, setValue] = useState<T | null>(null);
  const [isResolved, setIsResolved] = useState(false);
  useEffect(() => {
    let abort = false;
    setIsResolved(false);
    p &&
      p.then((v) => {
        if (abort) return;
        setValue(v);
        setIsResolved(true);
      });
    return () => {
      abort = true;
    };
  }, [p]);
  const returnValue: {
    value: T | null;
    isResolved: boolean;
  } = useMemo(() => ({ value, isResolved }), [value, isResolved]);
  return returnValue;
}
