import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useGqlFetch } from '../hooks/Api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
};

export type Account = {
  __typename?: 'Account';
  apiKeys: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: Array<User>;
};

export type AccountLoad = {
  __typename?: 'AccountLoad';
  account: Account;
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  usdCents: Scalars['Int']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type AccountLoadInput = {
  accountId: Scalars['ID']['input'];
  returnUrl: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum AiModel {
  Gpt_3_5Turbo = 'gpt_3_5_turbo',
  Gpt_3_5Turbo_16k = 'gpt_3_5_turbo_16k',
  Gpt_4 = 'gpt_4',
  Gpt_4Turbo = 'gpt_4_turbo',
  Gpt_4Vision = 'gpt_4_vision',
  TextEmbeddingAda_002 = 'text_embedding_ada_002'
}

export type AiRequest = {
  __typename?: 'AiRequest';
  account: Account;
  accountId: Scalars['ID']['output'];
  costs: Array<AiRequestCost>;
  createdAt: Scalars['DateTime']['output'];
  dataSource?: Maybe<DataSource>;
  dataSourceInputJson?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<ErrorType>;
  id: Scalars['ID']['output'];
  includeReferences?: Maybe<Scalars['Boolean']['output']>;
  maxCharacters?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<AiModel>;
  prompt?: Maybe<DataSource>;
  promptInputJson?: Maybe<Scalars['String']['output']>;
  references?: Maybe<Array<Scalars['String']['output']>>;
  response?: Maybe<Scalars['String']['output']>;
  status: AiRequestStatus;
  temperature?: Maybe<Scalars['Float']['output']>;
  type: AiRequestType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AiRequestCost = {
  __typename?: 'AiRequestCost';
  inputTokenUsdCents: Scalars['Float']['output'];
  inputTokens: Scalars['Int']['output'];
  model: AiModel;
  outputTokenUsdCents: Scalars['Float']['output'];
  outputTokens: Scalars['Int']['output'];
};

export type AiRequestInput = {
  accountId: Scalars['ID']['input'];
  dataSource?: InputMaybe<DataSourceInput>;
  includeReferences?: InputMaybe<Scalars['Boolean']['input']>;
  maxCharacters?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<AiModel>;
  prompt?: InputMaybe<DataSourceInput>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  type: AiRequestType;
};

export enum AiRequestOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum AiRequestStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export enum AiRequestType {
  DataSourcePrompt = 'DATA_SOURCE_PROMPT',
  Facts = 'FACTS',
  Prompt = 'PROMPT',
  Summary = 'SUMMARY'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  account: Account;
  accountId: Scalars['ID']['output'];
  apiKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Token;
  refreshToken: Token;
  userId: Scalars['ID']['output'];
};

export type ChatMessageInput = {
  content: DataSourceInput;
  role: ChatMessageRole;
};

export enum ChatMessageRole {
  Assistant = 'ASSISTANT',
  System = 'SYSTEM',
  User = 'USER'
}

export type CreateAccountInput = {
  createdByUserId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateApiKeyInput = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DataSource = {
  __typename?: 'DataSource';
  aiRequest?: Maybe<AiRequest>;
  aiRequestId?: Maybe<Scalars['ID']['output']>;
  array?: Maybe<Array<DataSource>>;
  dataSource?: Maybe<DataSource>;
  json?: Maybe<Scalars['String']['output']>;
  textContent?: Maybe<Scalars['String']['output']>;
  textReference?: Maybe<Scalars['String']['output']>;
  type: DataSourceType;
  url?: Maybe<Scalars['String']['output']>;
};

export type DataSourceInput = {
  aiRequest?: InputMaybe<AiRequestInput>;
  array?: InputMaybe<Array<DataSourceInput>>;
  chat?: InputMaybe<Array<ChatMessageInput>>;
  dataSource?: InputMaybe<DataSourceInput>;
  textContent?: InputMaybe<Scalars['String']['input']>;
  textReference?: InputMaybe<Scalars['String']['input']>;
  type: DataSourceType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum DataSourceType {
  AiRequest = 'AI_REQUEST',
  Array = 'ARRAY',
  Chat = 'CHAT',
  GoogleSearch = 'GOOGLE_SEARCH',
  Sacra = 'SACRA',
  Text = 'TEXT',
  Url = 'URL'
}

export enum ErrorType {
  AiRequestSizeError = 'AiRequestSizeError',
  AuthenticationFailure = 'AuthenticationFailure',
  AuthorizationFailure = 'AuthorizationFailure',
  InsufficientBalance = 'InsufficientBalance',
  InvalidEmailVerificationCode = 'InvalidEmailVerificationCode',
  ScrapeFailure = 'ScrapeFailure',
  UnexpectedError = 'UnexpectedError',
  UserInputValidationFailure = 'UserInputValidationFailure'
}

export type GetAccountLoadsInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAccountsInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GetAiRequestCostInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetAiRequestsInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AiRequestOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<AiRequestStatus>>;
};

export type GetApiKeysInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  apiKeys?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetUsersInput = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserToAccount: Account;
  createAccount: Account;
  createAiRequest: AiRequest;
  createAiRequestAsync: AiRequest;
  createApiKey: ApiKey;
  createPaymentLink: Scalars['String']['output'];
  deleteAccount: Scalars['Boolean']['output'];
  deleteAiRequest: AiRequest;
  deleteApiKeys: Scalars['Int']['output'];
  estimateAiRequestCost: Array<AiRequestCost>;
  logIn: AuthResponse;
  refreshAccessToken: Token;
  removeUserFromAccount: Account;
  revokeAllRefreshTokens: Scalars['Boolean']['output'];
  /**
   * Revokes access to the given token. Any requests attempted with the token will
   * get rejected if it's been revoked. Returns true if successful, false
   * otherwise.
   */
  revokeRefreshToken: Scalars['Boolean']['output'];
  sendLogInCode: Scalars['String']['output'];
};


export type MutationAddUserToAccountArgs = {
  accountId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateAiRequestArgs = {
  input: AiRequestInput;
};


export type MutationCreateAiRequestAsyncArgs = {
  input: AiRequestInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreatePaymentLinkArgs = {
  input: AccountLoadInput;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAiRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteApiKeysArgs = {
  apiKeys: Array<Scalars['ID']['input']>;
};


export type MutationEstimateAiRequestCostArgs = {
  input: AiRequestInput;
};


export type MutationLogInArgs = {
  codeFromEmail: Scalars['String']['input'];
  codeFromRequest: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRemoveUserFromAccountArgs = {
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRevokeAllRefreshTokensArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationRevokeRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendLogInCodeArgs = {
  email: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  accountLoads: Array<AccountLoad>;
  accounts: Array<Account>;
  aiRequests: Array<AiRequest>;
  aiRequestsCount: Scalars['Int']['output'];
  aiRequestsUsage: Array<AiRequestCost>;
  apiKeys: Array<ApiKey>;
  apiVersion: Scalars['String']['output'];
  users: Array<User>;
};


export type QueryAccountLoadsArgs = {
  input: GetAccountLoadsInput;
};


export type QueryAccountsArgs = {
  input: GetAccountsInput;
};


export type QueryAiRequestsArgs = {
  input: GetAiRequestsInput;
};


export type QueryAiRequestsCountArgs = {
  input: GetAiRequestsInput;
};


export type QueryAiRequestsUsageArgs = {
  input: GetAiRequestCostInput;
};


export type QueryApiKeysArgs = {
  input: GetApiKeysInput;
};


export type QueryUsersArgs = {
  input: GetUsersInput;
};

export type Token = {
  __typename?: 'Token';
  expiresAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type UpdateAccountInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  accounts: Array<Account>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AiRequestFragment = { __typename?: 'AiRequest', id: string, response?: string | null, status: AiRequestStatus };

export type CreateAiRequestMutationVariables = Exact<{
  input: AiRequestInput;
}>;


export type CreateAiRequestMutation = { __typename?: 'Mutation', createAiRequest: { __typename?: 'AiRequest', id: string, response?: string | null, status: AiRequestStatus } };

export type CreateAiRequestAsyncMutationVariables = Exact<{
  input: AiRequestInput;
}>;


export type CreateAiRequestAsyncMutation = { __typename?: 'Mutation', createAiRequestAsync: { __typename?: 'AiRequest', id: string, response?: string | null, status: AiRequestStatus } };

export type AiRequestsQueryVariables = Exact<{
  input: GetAiRequestsInput;
}>;


export type AiRequestsQuery = { __typename?: 'Query', aiRequests: Array<{ __typename?: 'AiRequest', id: string, response?: string | null, status: AiRequestStatus }> };

export type DeleteAiRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAiRequestMutation = { __typename?: 'Mutation', deleteAiRequest: { __typename?: 'AiRequest', id: string } };

export type ApiKeysQueryVariables = Exact<{
  input: GetApiKeysInput;
}>;


export type ApiKeysQuery = { __typename?: 'Query', apiKeys: Array<{ __typename?: 'ApiKey', accountId: string }> };

export const namedOperations = {
  Query: {
    aiRequests: 'aiRequests',
    apiKeys: 'apiKeys'
  },
  Mutation: {
    createAiRequest: 'createAiRequest',
    createAiRequestAsync: 'createAiRequestAsync',
    deleteAiRequest: 'deleteAiRequest'
  },
  Fragment: {
    AiRequest: 'AiRequest'
  }
}
export const AiRequestFragmentDoc = `
    fragment AiRequest on AiRequest {
  id
  response
  status
}
    `;
export const CreateAiRequestDocument = `
    mutation createAiRequest($input: AiRequestInput!) {
  createAiRequest(input: $input) {
    ...AiRequest
  }
}
    ${AiRequestFragmentDoc}`;
export const useCreateAiRequestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAiRequestMutation, TError, CreateAiRequestMutationVariables, TContext>) =>
    useMutation<CreateAiRequestMutation, TError, CreateAiRequestMutationVariables, TContext>(
      ['createAiRequest'],
      useGqlFetch<CreateAiRequestMutation, CreateAiRequestMutationVariables>(CreateAiRequestDocument),
      options
    );
export const CreateAiRequestAsyncDocument = `
    mutation createAiRequestAsync($input: AiRequestInput!) {
  createAiRequestAsync(input: $input) {
    ...AiRequest
  }
}
    ${AiRequestFragmentDoc}`;
export const useCreateAiRequestAsyncMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAiRequestAsyncMutation, TError, CreateAiRequestAsyncMutationVariables, TContext>) =>
    useMutation<CreateAiRequestAsyncMutation, TError, CreateAiRequestAsyncMutationVariables, TContext>(
      ['createAiRequestAsync'],
      useGqlFetch<CreateAiRequestAsyncMutation, CreateAiRequestAsyncMutationVariables>(CreateAiRequestAsyncDocument),
      options
    );
export const AiRequestsDocument = `
    query aiRequests($input: GetAiRequestsInput!) {
  aiRequests(input: $input) {
    ...AiRequest
  }
}
    ${AiRequestFragmentDoc}`;
export const useAiRequestsQuery = <
      TData = AiRequestsQuery,
      TError = unknown
    >(
      variables: AiRequestsQueryVariables,
      options?: UseQueryOptions<AiRequestsQuery, TError, TData>
    ) =>
    useQuery<AiRequestsQuery, TError, TData>(
      ['aiRequests', variables],
      useGqlFetch<AiRequestsQuery, AiRequestsQueryVariables>(AiRequestsDocument).bind(null, variables),
      options
    );
export const DeleteAiRequestDocument = `
    mutation deleteAiRequest($id: ID!) {
  deleteAiRequest(id: $id) {
    id
  }
}
    `;
export const useDeleteAiRequestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAiRequestMutation, TError, DeleteAiRequestMutationVariables, TContext>) =>
    useMutation<DeleteAiRequestMutation, TError, DeleteAiRequestMutationVariables, TContext>(
      ['deleteAiRequest'],
      useGqlFetch<DeleteAiRequestMutation, DeleteAiRequestMutationVariables>(DeleteAiRequestDocument),
      options
    );
export const ApiKeysDocument = `
    query apiKeys($input: GetApiKeysInput!) {
  apiKeys(input: $input) {
    accountId
  }
}
    `;
export const useApiKeysQuery = <
      TData = ApiKeysQuery,
      TError = unknown
    >(
      variables: ApiKeysQueryVariables,
      options?: UseQueryOptions<ApiKeysQuery, TError, TData>
    ) =>
    useQuery<ApiKeysQuery, TError, TData>(
      ['apiKeys', variables],
      useGqlFetch<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument).bind(null, variables),
      options
    );