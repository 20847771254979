import { Ionicons } from "@expo/vector-icons";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import isEqual from "lodash.isequal";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActivityIndicator,
  Button,
  ScrollView,
  StyleProp,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { AiMarkdown } from "../components/AiMarkdown";
import { HeaderBackButton, HeaderIconButton } from "../components/HeaderButton";
import { KeyboardAvoidingView } from "../components/KeyboardAvoidingView";
import {
  AiRequestStatus,
  useAiRequestsQuery,
  useDeleteAiRequestMutation,
} from "../generated/gen-graphql";
import { useCreateNewChat } from "../hooks/Chats";
import { useDebounce } from "../hooks/Debounce";
import {
  DocSet,
  DocSetTypeMetadata,
  useDocAiRequest,
  useDocSet,
  useUpsertDoc,
} from "../hooks/Docs";
import { usePromise } from "../hooks/Promise";
import { useEnsureApiKey, useSettings } from "../hooks/Settings";
import { RootParamsList } from "../navigators/RootNavigator";
import { TabParamsList } from "../navigators/TabNavigator";
import { useTheme } from "../providers/Theme";
import { confirmAction } from "../utils/string";

export const DocSetScreen: FC = () => {
  const { id } = useRoute<RouteProp<RootParamsList, "DocSet">>().params;
  const { data, isLoading } = useDocSet(id);
  const { setOptions, navigate } =
    useNavigation<
      CompositeNavigationProp<
        NativeStackNavigationProp<RootParamsList>,
        NativeStackNavigationProp<TabParamsList>
      >
    >();
  const theme = useTheme();

  useEnsureApiKey();

  const [updatedData, setUpdatedData] = useState<DocSet>();
  useEffect(() => {
    if (updatedData) return;
    setUpdatedData(data);
  }, [data, updatedData]);

  useEffect(() => {
    if (!updatedData) return;
    if (updatedData.docs.length) return;
    setUpdatedData({ ...updatedData, docs: [""] });
  }, [updatedData]);

  const { mutate } = useUpsertDoc();
  useDebounce(() => {
    if (!updatedData) return;
    if (isEqual(data, updatedData)) return;
    mutate(updatedData);
  }, [data, mutate, updatedData]);

  const pendingAiRequestId = updatedData?.pendingResponseId || "";
  const { data: pendingAiRequest } = useAiRequestsQuery(
    {
      input: { ids: [pendingAiRequestId] },
    },
    {
      enabled: !!pendingAiRequestId,
      refetchInterval: 500,
    }
  );
  const { mutate: del } = useDeleteAiRequestMutation();
  const { get } = useSettings();
  const [settingsPromise] = useState(() => get());
  const { value: settings } = usePromise(settingsPromise);
  useEffect(() => {
    if (!settings) return;
    if (!updatedData) return;
    const pendingRequest = pendingAiRequest?.aiRequests?.[0];
    if (!pendingRequest) return;
    if (
      pendingRequest.status !== AiRequestStatus.Completed &&
      pendingRequest.status !== AiRequestStatus.Failed
    )
      return;
    const newData = { ...updatedData };
    delete newData.pendingResponseId;
    newData.response = pendingRequest.response || "Something went wrong.";
    setUpdatedData(newData);
    if (settings.deleteRequests) del({ id: pendingRequest.id });
  }, [del, pendingAiRequest, settings, updatedData]);

  useEffect(() => {
    if (isLoading) return;
    if (data) return;
    navigate("DocSets");
  }, [data, isLoading, navigate]);

  const { mutateAsync: createChat } = useCreateNewChat();

  useLayoutEffect(() => {
    if (!updatedData) return;
    setOptions({
      title: "Document" + (updatedData.docs.length === 1 ? "" : "s"),
      headerLeft: () => <HeaderBackButton fallback="DocSets" />,
      headerRight: () => (
        <HeaderIconButton
          name="chatbox"
          onPress={async () => {
            const chat = await createChat({
              docSetId: updatedData.id,
              type: "turbo_large",
            });
            navigate("Chat", { id: chat.id });
          }}
        />
      ),
    });
  }, [createChat, navigate, setOptions, updatedData]);

  const scrollViewRef = useRef<ScrollView>(null);

  return !updatedData ? (
    <ActivityIndicator color={theme.colors.primary} />
  ) : (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{
          padding: theme.spacing.m,
          gap: theme.spacing.m,
        }}
      >
        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Name
        </Text>
        <TextInput
          placeholder="Enter a name to identify this document set"
          placeholderTextColor={theme.colors.onSurface + "80"}
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            color: theme.colors.onSurface,
            padding: theme.spacing.m,
          }}
          value={updatedData.name}
          onChangeText={(name) => setUpdatedData({ ...updatedData, name })}
          onBlur={() => {
            const name = updatedData.name.trim();
            setUpdatedData({ ...updatedData, name });
          }}
        />
        {updatedData.docs.map((doc, i) => (
          <View
            key={i}
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
              gap: theme.spacing.m,
            }}
          >
            <View style={{ flex: 1, gap: theme.spacing.s }}>
              <Text
                style={[
                  theme.typography.subtitle1,
                  { color: theme.colors.onBackground, flex: 1 },
                ]}
              >
                Doc {i + 1}
              </Text>

              <TextInput
                onBlur={() => {
                  const docs = updatedData.docs.concat();
                  docs[i] = docs[i].trim();
                  setUpdatedData({ ...updatedData, docs });
                }}
                placeholder="Enter text or a URL"
                placeholderTextColor={theme.colors.onSurface + "80"}
                multiline
                style={{
                  borderWidth: theme.border.width.s,
                  borderColor: theme.colors.border,
                  borderRadius: theme.border.radius.s,
                  backgroundColor: theme.colors.surface,
                  color: theme.colors.onSurface,
                  padding: theme.spacing.m,
                  height: 100,
                }}
                value={doc}
                onChangeText={(text) => {
                  const docs = updatedData.docs.concat();
                  docs[i] = text;
                  setUpdatedData({ ...updatedData, docs });
                }}
              />
            </View>
            {
              <Ionicons.Button
                disabled={updatedData.docs.length === 1}
                iconStyle={{ marginRight: 0 }}
                name="close"
                color={theme.colors.onError}
                backgroundColor={
                  updatedData.docs.length === 1
                    ? theme.colors.error + "40"
                    : theme.colors.error
                }
                onPress={() => {
                  confirmAction({
                    title: "Are you sure you want to delete this doc?",
                    onConfirm: () => {
                      const docs = updatedData.docs.concat();
                      docs.splice(i, 1);
                      setUpdatedData({ ...updatedData, docs });
                    },
                  });
                }}
              />
            }
          </View>
        ))}
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Ionicons.Button
            name="add"
            iconStyle={{ marginRight: 0 }}
            backgroundColor={theme.colors.primary}
            color={theme.colors.onPrimary}
            onPress={() => {
              const docs = updatedData.docs.concat();
              docs.push("");
              setUpdatedData({ ...updatedData, docs });
            }}
          />
        </View>
        <FormView
          docSet={updatedData}
          onDocSetChanged={(docSet) => setUpdatedData(docSet)}
          onRequest={() => {
            setTimeout(() => scrollViewRef.current?.scrollToEnd(), 100);
          }}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const FormView: FC<{
  docSet: DocSet;
  onDocSetChanged: (docSet: DocSet) => void;
  onRequest?: () => void;
  style?: StyleProp<ViewStyle>;
}> = ({ docSet, style, onDocSetChanged, onRequest }) => {
  const theme = useTheme();
  const { navigate } =
    useNavigation<NativeStackNavigationProp<RootParamsList>>();
  const meta = DocSetTypeMetadata.find((o) => o.type === docSet.type);
  const { mutate, isLoading, data } = useDocAiRequest();

  useEffect(() => {
    if (!data) return;
    if (docSet.pendingResponseId === data) return;
    if (docSet.response) return;
    onDocSetChanged({
      ...docSet,
      pendingResponseId: data,
      response: undefined,
    });
  }, [data, docSet, onDocSetChanged]);

  const errorMsg = useMemo(() => {
    if (!docSet.docs.join("")) return "Please enter some text to summarize.";
    if (!docSet.maxCharacters) return "Please enter a response length.";
  }, [docSet]);
  const [showErrors, setShowErrors] = useState(false);

  const onRequestRef = useRef(onRequest);
  onRequestRef.current = onRequest;
  useEffect(() => {
    if (!isLoading) return;
    onRequestRef.current?.();
  }, [isLoading, onRequest]);

  const onGo = async () => {
    onDocSetChanged({
      ...docSet,
      pendingResponseId: undefined,
      response: undefined,
    });
    setShowErrors(!!errorMsg);
    if (errorMsg) {
      onRequest?.();
      return;
    }
    mutate(docSet);
  };

  return (
    meta && (
      <View style={[style, { gap: theme.spacing.m }]}>
        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Type
        </Text>
        <TouchableOpacity
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
          }}
          onPress={() => {
            navigate("EditDocSetType", {
              callback: (type) => {
                onDocSetChanged({ ...docSet, type });
              },
            });
          }}
        >
          <Text style={[{ color: theme.colors.onSurface }]}>{meta.name}</Text>
        </TouchableOpacity>

        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Prompt
        </Text>
        <TextInput
          placeholder={meta.promptPlaceholder}
          placeholderTextColor={theme.colors.onSurface + "80"}
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            color: theme.colors.onSurface,
          }}
          value={docSet.prompt}
          onChangeText={(prompt) => onDocSetChanged({ ...docSet, prompt })}
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === "Enter") onGo();
          }}
        />

        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Response Length
        </Text>
        <TextInput
          keyboardType="number-pad"
          placeholder="Enter the estimated number of characters to respond with."
          placeholderTextColor={theme.colors.onSurface + "80"}
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            color: theme.colors.onSurface,
          }}
          value={docSet.maxCharacters ? docSet.maxCharacters.toString() : ""}
          onChangeText={(prompt) =>
            onDocSetChanged({ ...docSet, maxCharacters: parseInt(prompt) || 0 })
          }
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === "Enter") onGo();
          }}
        />
        <Button
          color={theme.colors.primary}
          title="Go"
          onPress={onGo}
          disabled={isLoading || !!docSet.pendingResponseId}
        />
        {showErrors && errorMsg && (
          <View
            style={{
              borderRadius: theme.border.radius.s,
              backgroundColor: theme.colors.error,
              padding: theme.spacing.m,
            }}
          >
            <Text
              style={[
                theme.typography.subtitle2,
                { color: theme.colors.onError },
              ]}
            >
              {errorMsg}
            </Text>
          </View>
        )}
        {docSet.pendingResponseId || docSet.response ? (
          <View
            style={{
              padding: theme.spacing.m,
              borderRadius: theme.border.radius.s,
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
              backgroundColor: theme.colors.surface,
            }}
          >
            {docSet.pendingResponseId && (
              <ActivityIndicator
                style={{ flex: 1 }}
                color={theme.colors.primary}
              />
            )}
            {!!docSet.response && (
              <AiMarkdown
                markdown={docSet.response}
                textColor={theme.colors.onBackground}
              />
            )}
          </View>
        ) : null}
      </View>
    )
  );
};
