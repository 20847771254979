import { useSettings } from "./Settings";

export const useGqlFetch = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"]
): ((variables?: TVariables) => Promise<TData>) => {
  const { get: getSettings } = useSettings();
  const serverUrl = "https://api.staging.wp-ai.twotors.com/graphql";
  // const serverUrl = "http://localhost:4020/graphql";
  return async (variables?: TVariables) => {
    const token = (await getSettings()).apiKey;
    if (!token) throw new Error("No API key");
    const res = await fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": token,
        ...options,
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();
    if (json.errors) throw json.errors;

    return json.data;
  };
};
