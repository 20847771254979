import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootNavigator } from "./navigators/RootNavigator";
import { LocalStorageProvider } from "./providers/LocalStorage";
import { SpeechProvider } from "./providers/Speech";
import { ThemeProvider } from "./providers/Theme";

function App() {
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: { queries: { retry: false, staleTime: Infinity } },
    });
  }, []);
  return (
    <SpeechProvider>
      <SafeAreaProvider>
        <ThemeProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <ActionSheetProvider>
              <LocalStorageProvider>
                <QueryClientProvider client={queryClient}>
                  <RootNavigator />
                </QueryClientProvider>
              </LocalStorageProvider>
            </ActionSheetProvider>
          </GestureHandlerRootView>
        </ThemeProvider>
      </SafeAreaProvider>
    </SpeechProvider>
  );
}

export default App;
