import { useIsFocused, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import moment from "moment";
import { FC, useEffect, useLayoutEffect, useMemo } from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Swipeable, TouchableHighlight } from "react-native-gesture-handler";
import { HeaderIconButton } from "../components/HeaderButton";
import {
  ChatTypeMetadata,
  useChats,
  useCreateNewChat,
  useDeleteChat,
} from "../hooks/Chats";
import { RootParamsList } from "../navigators/RootNavigator";
import { useTheme } from "../providers/Theme";

export const ChatListScreen: FC = () => {
  const { mutate: deleteChat } = useDeleteChat();

  const { data: chats } = useChats();
  const { mutateAsync } = useCreateNewChat();

  const sortedChats = useMemo(() => {
    if (!chats) return chats;
    return chats
      .concat()
      .sort((a, b) =>
        moment(b.messages[b.messages.length - 1]?.date).diff(
          moment(a.messages[a.messages.length - 1]?.date)
        )
      );
  }, [chats]);

  const { setOptions, navigate } =
    useNavigation<NativeStackNavigationProp<RootParamsList>>();

  const theme = useTheme();

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) return;
    let abort = false;

    (async () => {
      for (const chat of chats || []) {
        if (chat.messages.length) continue;
        await deleteChat(chat.id);
        if (abort) return;
      }
    })();

    return () => {
      abort = true;
    };
  }, [chats, deleteChat, isFocused]);

  useLayoutEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderIconButton
          name="md-add"
          isTab
          onPress={async () => {
            const chat = await mutateAsync({});
            navigate("Chat", { id: chat.id });
          }}
        />
      ),
    });
  }, [mutateAsync, navigate, setOptions, theme]);

  return (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={sortedChats}
      ListEmptyComponent={() =>
        !sortedChats ? (
          <ActivityIndicator color={theme.colors.primary} />
        ) : (
          <View
            style={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                theme.typography.h2,
                { color: theme.colors.onBackground },
              ]}
            >
              No chats
            </Text>
          </View>
        )
      }
      renderItem={({ item: chat }) => (
        <Swipeable
          renderRightActions={() => (
            <TouchableOpacity
              onPress={() => deleteChat(chat.id)}
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: theme.spacing.l,
                backgroundColor: theme.colors.error,
                height: "100%",
              }}
            >
              <Text
                style={[
                  { color: theme.colors.onSecondary },
                  theme.typography.button,
                ]}
              >
                Delete
              </Text>
            </TouchableOpacity>
          )}
        >
          <TouchableHighlight onPress={() => navigate("Chat", { id: chat.id })}>
            <View
              style={{
                backgroundColor: theme.colors.surface,
                padding: theme.spacing.m,
                borderBottomWidth: theme.border.width.s,
                borderBottomColor: theme.colors.border,
                flexDirection: "row",
                gap: theme.spacing.xs,
                width: "100%",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  numberOfLines={1}
                  style={[
                    theme.typography.subtitle1,
                    { color: theme.colors.onSurface },
                  ]}
                >
                  {chat.name || "Unnamed"}
                </Text>
                <Text
                  style={[
                    theme.typography.caption,
                    {
                      color: theme.colors.onSurface,
                      marginTop: theme.spacing.xs,
                    },
                  ]}
                  numberOfLines={2}
                >
                  {chat.messages[chat.messages.length - 1]?.text || ""}
                </Text>
              </View>
              <View style={{ alignItems: "flex-end" }}>
                {chat.messages.length === 0 ? null : (
                  <>
                    <Text
                      style={[
                        theme.typography.caption,
                        { flex: 0, color: theme.colors.onSurface },
                      ]}
                    >
                      {moment(
                        chat.messages[chat.messages.length - 1]?.date
                      ).format("l")}
                    </Text>
                    <Text
                      style={[
                        theme.typography.caption,
                        { flex: 0, color: theme.colors.onSurface },
                      ]}
                    >
                      {moment(
                        chat.messages[chat.messages.length - 1]?.date
                      ).format("LT")}
                    </Text>
                    <Text
                      style={[
                        theme.typography.caption,
                        {
                          marginTop: theme.spacing.xs,
                          color: theme.colors.onSurface,
                        },
                      ]}
                    >
                      {chat.docSetId ? "Doc " : ""}
                      {
                        ChatTypeMetadata.find((m) => m.type === chat.type)
                          ?.shortName
                      }
                    </Text>
                  </>
                )}
              </View>
            </View>
          </TouchableHighlight>
        </Swipeable>
      )}
    />
  );
};
