import { FC, PropsWithChildren } from "react";
import {
  KeyboardAvoidingView as KeyboardAvoidingView_,
  ViewStyle,
} from "react-native";
import { isIOS } from "../utils/device";

export const KeyboardAvoidingView: FC<
  PropsWithChildren<{
    style?: ViewStyle;
  }>
> = ({ children, style }) => {
  return (
    <KeyboardAvoidingView_
      style={style}
      behavior={isIOS() ? "padding" : undefined}
      keyboardVerticalOffset={isIOS() ? 64 : 0}
    >
      {children}
    </KeyboardAvoidingView_>
  );
};
