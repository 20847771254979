import { StatusBar } from "expo-status-bar";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { TextStyle, useColorScheme } from "react-native";

type Colors = {
  primary: string;
  onPrimary: string;
  secondary: string;
  onSecondary: string;
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  error: string;
  onError: string;
  success: string;
  onSuccess: string;
  border: string;
};

export type Theme = {
  colors: Colors;
  spacing: {
    xs: number;
    s: number;
    m: number;
    l: number;
    xl: number;
  };
  typography: {
    h1: TextStyle;
    h2: TextStyle;
    h3: TextStyle;
    h4: TextStyle;
    h5: TextStyle;
    h6: TextStyle;
    subtitle1: TextStyle;
    subtitle2: TextStyle;
    body1: TextStyle;
    body2: TextStyle;
    button: TextStyle;
    caption: TextStyle;
  };
  border: {
    radius: {
      s: number;
      m: number;
      l: number;
    };
    width: {
      s: number;
      m: number;
      l: number;
    };
  };
};

const LIGHT_COLORS: Colors = {
  primary: "#E64A19",
  onPrimary: "#FFFFFF",
  secondary: "#2D3789",
  onSecondary: "#FFFFFF",
  background: "#F8F8F8",
  onBackground: "#000000",
  surface: "#FFFFFF",
  onSurface: "#000000",
  error: "#FF3333",
  onError: "#FFFFFF",
  success: "#008000",
  onSuccess: "#000000",
  border: "#E0E0E0",
};

const DARK_COLORS: Colors = {
  primary: "#E64A19",
  onPrimary: "#FFFFFF",
  secondary: "#2D3789",
  onSecondary: "#FFFFFF",
  background: "#111111",
  onBackground: "#FFFFFF",
  surface: "#000000",
  onSurface: "#FFFFFF",
  error: "#FF3333",
  onError: "#FFFFFF",
  success: "#008000",
  onSuccess: "#FFFFFF",
  border: "#444444",
};

const DEFAULT_THEME: Theme = {
  colors: LIGHT_COLORS,
  border: {
    radius: {
      s: 8,
      m: 16,
      l: 32,
    },
    width: {
      s: 1,
      m: 2,
      l: 4,
    },
  },
  spacing: {
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 32,
  },
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: "bold",
      lineHeight: 40,
    },
    h2: {
      fontSize: 24,
      fontWeight: "bold",
      lineHeight: 32,
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: 28,
    },
    h4: {
      fontSize: 18,
      fontWeight: "bold",
      lineHeight: 24,
    },
    h5: {
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 24,
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: 20,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: 24,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: 20,
    },
    body1: {
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: 24,
    },
    body2: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: 20,
    },
    button: {
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: 16,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: 16,
    },
  },
};

const Context = createContext<Theme>(DEFAULT_THEME);
export const useTheme = () => useContext(Context);
export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const colorScheme = useColorScheme();
  const theme = useMemo(() => {
    const colors = colorScheme === "dark" ? DARK_COLORS : LIGHT_COLORS;
    return { ...DEFAULT_THEME, colors };
  }, [colorScheme]);
  return (
    <Context.Provider value={theme}>
      <StatusBar style={colorScheme === "dark" ? "light" : "dark"} />
      {children}
    </Context.Provider>
  );
};
