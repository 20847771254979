import { useMemo } from "react";
import { useTheme } from "../providers/Theme";

export const useHeaderTitleStyle = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
    }),
    [theme]
  );
};
