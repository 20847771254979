import AsyncStorage from '@react-native-async-storage/async-storage';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

export interface LocalStorageProps {
  get: (key: string) => Promise<string>;
  set: (key: string, value: string) => Promise<void>;
}

export const LocalStorageContext = createContext<LocalStorageProps>({
  get: async () => {
    throw 'No LocalStorageContext';
  },
  set: async () => {
    throw 'No LocalStorageContext';
  },
});

export const useLocalStorage = () => useContext(LocalStorageContext);
export const LocalStorageProvider: FC<PropsWithChildren> = ({ children }) => {
  const get = async (key: string) => {
    return await AsyncStorage.getItem(key) || '';
  };
  const set = async (key: string, value: string) => {
    await AsyncStorage.setItem(key, value);
  };
  return (
    <LocalStorageContext.Provider value={{ get, set }}>
      {children}
    </LocalStorageContext.Provider>
  );
}