import { useIsFocused, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import isEqual from "lodash.isequal";
import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  ActivityIndicator,
  Button,
  ScrollView,
  Switch,
  Text,
  TextInput,
  View,
} from "react-native";
import { HeaderIconButton } from "../components/HeaderButton";
import { KeyboardAvoidingView } from "../components/KeyboardAvoidingView";
import { useApiKeysQuery } from "../generated/gen-graphql";
import { useChats, useDeleteChat } from "../hooks/Chats";
import { usePromise } from "../hooks/Promise";
import { SettingsSchema, useSettings } from "../hooks/Settings";
import { TabParamsList } from "../navigators/TabNavigator";
import { useTheme } from "../providers/Theme";
import { confirmAction, showAlert } from "../utils/string";

export const SettingsScreen: FC = () => {
  const theme = useTheme();
  const { setOptions, navigate } =
    useNavigation<NativeStackNavigationProp<TabParamsList>>();

  const { get, set } = useSettings();
  const promiseFactory = useCallback(
    () => get().then((v) => ({ ...v, temperature: v.temperature.toString() })),
    [get]
  );
  const [promise, setPromise] = useState(promiseFactory);
  const { value, isResolved } = usePromise(promise);
  const [settings, setSettings] = useState<typeof value>();

  useEffect(() => {
    if (!isResolved) return;
    setSettings(value);
  }, [isResolved, value]);

  const { data: chats } = useChats();
  const { mutateAsync: deleteChat } = useDeleteChat();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { refetch } = useApiKeysQuery(
    { input: { apiKeys: [settings?.apiKey || ""] } },
    { enabled: false }
  );

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) return;
    setIsSubmitted(false);
    setPromise(promiseFactory);
  }, [isFocused, promiseFactory]);

  useEffect(() => {
    if (!settings) return;
    if (!isSubmitted) return;

    const parsedSettings = SettingsSchema.safeParse(settings);
    if (!parsedSettings.success) {
      const errorMsg = Object.entries(
        parsedSettings.error.formErrors.fieldErrors
      )
        .map(([field, error]) => `${field}: ${error}`)
        .join("\n");
      showAlert("Invalid settings", errorMsg);
      setIsSubmitted(false);
      return;
    }

    let abort = false;
    (async () => {
      if (abort) return;
      const originalSettings = await get();
      if (abort) return;
      await set({ ...parsedSettings.data, accountId: "" });
      if (abort) return;

      if (!settings.apiKey) {
        navigate("ChatList");
        return;
      }

      const data = await refetch();
      const accountId = data?.data?.apiKeys[0]?.accountId;
      if (!accountId) {
        await set(originalSettings);
        showAlert("Invalid API Key");
        setIsSubmitted(false);
        return;
      }

      await set({ ...parsedSettings.data, accountId });
      if (abort) return;
      navigate("ChatList");
    })();

    return () => {
      abort = true;
    };
  }, [get, isSubmitted, navigate, refetch, set, settings]);

  useLayoutEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderIconButton
          isTab
          disabled={!isResolved || isEqual(value, settings) || isSubmitted}
          name="save"
          onPress={async () => {
            setIsSubmitted(true);
          }}
        />
      ),
    });
  }, [isResolved, isSubmitted, navigate, set, setOptions, settings, value]);

  return !settings ? (
    <ActivityIndicator color={theme.colors.primary} />
  ) : (
    <KeyboardAvoidingView style={{ height: "100%" }}>
      <ScrollView
        keyboardDismissMode="on-drag"
        contentContainerStyle={{
          gap: theme.spacing.m,
          padding: theme.spacing.m,
        }}
      >
        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          API Key
        </Text>
        <TextInput
          placeholderTextColor={theme.colors.onSurface + "80"}
          style={{
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            color: theme.colors.onSurface,
            padding: theme.spacing.m,
          }}
          value={settings.apiKey}
          onChangeText={(apiKey) => setSettings({ ...settings, apiKey })}
        />

        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Chat Settings
        </Text>

        <View
          style={{
            borderRadius: theme.border.radius.s,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            gap: theme.spacing.m,
          }}
        >
          <Text
            style={[
              theme.typography.subtitle1,
              { color: theme.colors.onSurface },
            ]}
          >
            Temperature
          </Text>
          <Text
            style={[
              theme.typography.subtitle2,
              { color: theme.colors.onSurface },
            ]}
          >
            This is a measure of how creative the AI will be. A higher value
            will result in more creative responses but may also result in more
            nonsensical responses. It is recommended to keep this at 0 but feel
            free to experiment.
          </Text>
          <TextInput
            inputMode="numeric"
            placeholderTextColor={theme.colors.onSurface + "80"}
            style={{
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
              borderRadius: theme.border.radius.s,
              backgroundColor: theme.colors.surface,
              color: theme.colors.onSurface,
              padding: theme.spacing.m,
            }}
            value={settings.temperature.toString()}
            onChangeText={(temperature) =>
              setSettings({ ...settings, temperature })
            }
          />

          <Text
            style={[
              theme.typography.subtitle2,
              { color: theme.colors.onSurface },
            ]}
          >
            For the following inputs, feel free to write anything in them but
            remember that your responses will cut into your token limits so it
            is best to keep them as concise as possible. Or keep them blank if
            you want.
          </Text>

          <Text
            style={[
              theme.typography.subtitle1,
              { color: theme.colors.onBackground },
            ]}
          >
            What would you like the AI to know about you to provide better
            responses?
          </Text>
          <TextInput
            multiline
            placeholder={`Ideas: Your name, location, occupation, hobbies, interests, goals, etc.`}
            numberOfLines={5}
            maxLength={1500}
            placeholderTextColor={theme.colors.onSurface + "80"}
            style={{
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
              borderRadius: theme.border.radius.s,
              backgroundColor: theme.colors.surface,
              color: theme.colors.onSurface,
              padding: theme.spacing.m,
            }}
            value={settings.aboutUser}
            onChangeText={(aboutUser) =>
              setSettings({ ...settings, aboutUser })
            }
          />
          <Text
            style={[
              theme.typography.subtitle1,
              { color: theme.colors.onBackground },
            ]}
          >
            How would you like the AI to respond to your requests?
          </Text>
          <TextInput
            multiline
            placeholder={`Ideas: Informal, prefer short responses, always address me as master, have a politically centered opinion, etc.`}
            numberOfLines={5}
            maxLength={1500}
            placeholderTextColor={theme.colors.onSurface + "80"}
            style={{
              borderWidth: theme.border.width.s,
              borderColor: theme.colors.border,
              borderRadius: theme.border.radius.s,
              backgroundColor: theme.colors.surface,
              color: theme.colors.onSurface,
              padding: theme.spacing.m,
            }}
            value={settings.responsePreferences}
            onChangeText={(responsePreferences) =>
              setSettings({ ...settings, responsePreferences })
            }
          />
        </View>

        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Delete Completed Requests from WP-AI
        </Text>
        <View
          style={{
            flexDirection: "row",
            gap: theme.spacing.m,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            borderRadius: theme.border.radius.s,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
            alignItems: "center",
          }}
        >
          <Text style={[{ color: theme.colors.onSurface, flex: 1 }]}>
            WP-AI keeps a history of all requests that are made. By selecting
            this, all requests will be deleted from WP-AI after they have been
            completed.
          </Text>
          <Switch
            value={settings.deleteRequests}
            onValueChange={(deleteRequests) =>
              setSettings({ ...settings, deleteRequests })
            }
          />
        </View>
        <View
          style={{
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            alignItems: "center",
            justifyContent: "center",
            gap: theme.spacing.m,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
          }}
        >
          <Text
            style={[
              theme.typography.subtitle1,
              { color: theme.colors.onSurface },
            ]}
          >
            To create an API key, go to:
          </Text>
          <Text
            selectable
            style={[
              theme.typography.subtitle1,
              { color: theme.colors.onSurface },
            ]}
          >
            https://wp-ai-dashboard.vercel.app/
          </Text>

          <Button
            title="Open WP-AI"
            color={theme.colors.primary}
            onPress={() =>
              Linking.openURL("https://wp-ai-dashboard.vercel.app/")
            }
          />
        </View>
        <Text
          style={[
            theme.typography.subtitle1,
            { color: theme.colors.onBackground },
          ]}
        >
          Danger Zone
        </Text>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.m,
            borderRadius: theme.border.radius.s,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
          }}
        >
          <Button
            title="Delete all chats"
            color={theme.colors.error}
            onPress={() => {
              confirmAction({
                title: "Are you sure you want to delete all chats?",
                onConfirm: async () => {
                  for (const chat of chats!) {
                    await deleteChat(chat.id);
                  }
                },
              });
            }}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};
