import {
  LinkingOptions,
  NavigationContainer,
  getPathFromState,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { FC } from "react";
import { useColorScheme } from "react-native";
import { DocSetType } from "../hooks/Docs";
import { useHeaderTitleStyle } from "../hooks/Theme";
import { useTheme } from "../providers/Theme";
import { ChatScreen } from "../screens/ChatScreen";
import { DocSetScreen } from "../screens/DocSetScreen";
import { EditChatTypeScreen } from "../screens/EditChatTypeScreen";
import { EditDocSetTypeScreen } from "../screens/EditDocSetTypeScreen";
import { MessageOptionsScreen } from "../screens/MessageOptionsScreen";
import { TabNavigator, TabParamsList } from "./TabNavigator";

export type RootParamsList = {
  Tabs: undefined;
  Chat: { id: string; isFork?: boolean };
  MessageOptions: { chatId: string; messageId: string };
  EditChatType: { chatId: string };
  DocSet: { id: string };
  EditDocSetType: { callback: (type: DocSetType) => void };
};

const Stack = createNativeStackNavigator<RootParamsList>();

const linking: LinkingOptions<RootParamsList & TabParamsList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Tabs: {
        path: "",
        screens: {
          ChatList: "chat",
          Settings: "settings",
          DocSets: "docs",
        },
      },
      Chat: "chat/:id",
      DocSet: "docs/:id",
      Google: "google",
    },
  },
  getPathFromState: (state, options) => {
    if (state.routes[state.routes.length - 1]?.name === "EditDocSetType") {
      return "/EditDocSetType";
    }
    return getPathFromState(state, options);
  },
  getStateFromPath: (path, options) => {
    const parts = path.split("/").filter((s) => !!s);
    const [screen, id] = parts;
    console.log(parts);
    if (screen === "settings") {
      return {
        routes: [{ name: "Tabs", state: { routes: [{ name: "Settings" }] } }],
      };
    }
    if (screen === "chat" && !id) {
      return {
        routes: [{ name: "Tabs", state: { routes: [{ name: "ChatList" }] } }],
      };
    }
    if (screen === "chat" && id) {
      return {
        routes: [
          { name: "Tabs", state: { routes: [{ name: "ChatList" }] } },
          { name: "Chat", params: { id } },
        ],
      };
    }
    if (screen === "docs" && !id) {
      return {
        routes: [{ name: "Tabs", state: { routes: [{ name: "DocSets" }] } }],
      };
    }
    if (screen === "docs" && id) {
      return {
        routes: [
          { name: "Tabs", state: { routes: [{ name: "DocSets" }] } },
          { name: "DocSet", params: { id } },
        ],
      };
    }
    if (screen === "google") {
      return {
        routes: [
          {
            name: "Tabs",
            state: { routes: [{ name: "Google" }] },
          },
        ],
      };
    }
    return undefined;
  },
};

export const RootNavigator: FC = () => {
  const theme = useTheme();
  const colorScheme = useColorScheme();
  const headerTitleStyle = useHeaderTitleStyle();
  return (
    <NavigationContainer
      linking={linking}
      theme={{
        colors: {
          background: theme.colors.background,
          border: theme.colors.border,
          card: theme.colors.surface,
          notification: theme.colors.error,
          primary: theme.colors.primary,
          text: theme.colors.onSurface,
        },
        dark: colorScheme === "dark",
      }}
    >
      <Stack.Navigator
        initialRouteName="Tabs"
        screenOptions={{ headerTitleStyle }}
      >
        <Stack.Screen
          name="Tabs"
          component={TabNavigator}
          options={{ headerShown: false }}
        />
        <Stack.Screen name="Chat" component={ChatScreen} />
        <Stack.Screen
          name="MessageOptions"
          component={MessageOptionsScreen}
          options={{ presentation: "modal", title: "Message Options" }}
        />
        <Stack.Screen
          name="EditChatType"
          component={EditChatTypeScreen}
          options={{ presentation: "modal", title: "Select Chat Type" }}
        />
        <Stack.Screen name="DocSet" component={DocSetScreen} />
        <Stack.Screen name="EditDocSetType" component={EditDocSetTypeScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
