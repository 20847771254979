import { FC, ReactNode } from "react";
import { Text, View } from "react-native";
import { useTheme } from "../providers/Theme";

export const EmptyScreen: FC<{
  icon?: ReactNode;
  title: string;
  subtitle: string;
}> = ({ icon, subtitle, title }) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: theme.spacing.l,
        gap: theme.spacing.m,
      }}
    >
      {icon && (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {icon}
        </View>
      )}
      <Text
        style={[
          theme.typography.h2,
          { color: theme.colors.onBackground, textAlign: "center" },
        ]}
      >
        {title}
      </Text>
      <Text
        style={[
          theme.typography.body1,
          { color: theme.colors.onBackground, textAlign: "center" },
        ]}
      >
        {subtitle}
      </Text>
    </View>
  );
};
