import {
  CompositeNavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { RootParamsList } from "../navigators/RootNavigator";
import { TabParamsList } from "../navigators/TabNavigator";
import { useLocalStorage } from "../providers/LocalStorage";
import { showAlert } from "../utils/string";

export const useEnsureApiKey = () => {
  const isFocused = useIsFocused();
  const { get } = useSettings();
  const { canGoBack, goBack, navigate, replace } =
    useNavigation<
      CompositeNavigationProp<
        NativeStackNavigationProp<TabParamsList>,
        NativeStackNavigationProp<RootParamsList>
      >
    >();

  useEffect(() => {
    if (!isFocused) return;
    let abort = false;
    (async () => {
      const { accountId } = await get();
      if (accountId) return;
      if (abort) return;
      showAlert("Please set an API key");
      if (canGoBack()) goBack();
      else replace("Tabs");
      navigate("Settings");
    })();
    return () => {
      abort = true;
    };
  }, [canGoBack, get, goBack, isFocused, navigate, replace]);
};

export const SettingsSchema = z.object({
  apiKey: z.string(),
  accountId: z.string(),
  deleteRequests: z.boolean().default(false),
  aboutUser: z.string().trim().default(""),
  responsePreferences: z.string().trim().default(""),
  temperature: z.coerce.number().min(0).max(1).default(0),
});
export type Settings = z.infer<typeof SettingsSchema>;

export const useSettings = () => {
  const { get, set } = useLocalStorage();
  return useMemo(
    () => ({
      get: async (): Promise<Settings> => {
        try {
          const settingsString = await get("settings");
          return SettingsSchema.parse(JSON.parse(settingsString));
        } catch (e) {
          return {
            apiKey: "",
            deleteRequests: false,
            accountId: "",
            aboutUser: "",
            responsePreferences: "",
            temperature: 0,
          };
        }
      },
      set: async (settings: Settings) => {
        await set("settings", JSON.stringify(SettingsSchema.parse(settings)));
      },
    }),
    [get, set]
  );
};
