import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { FC, useEffect, useLayoutEffect, useMemo } from "react";
import { ScrollView, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { HeaderBackButton } from "../components/HeaderButton";
import { Chat, Message, useChat, useUpsertChat } from "../hooks/Chats";
import { RootParamsList } from "../navigators/RootNavigator";
import { TabParamsList } from "../navigators/TabNavigator";
import { useTheme } from "../providers/Theme";

export const MessageOptionsScreen: FC = () => {
  const theme = useTheme();
  const { setOptions, navigate, replace } =
    useNavigation<
      CompositeNavigationProp<
        NativeStackNavigationProp<RootParamsList>,
        NativeStackNavigationProp<TabParamsList>
      >
    >();
  const route = useRoute<RouteProp<RootParamsList, "MessageOptions">>();
  const { messageId, chatId } = route.params;

  useLayoutEffect(() => {
    setOptions({
      headerLeft: () => <HeaderBackButton isClose fallback="ChatList" />,
    });
  }, [setOptions]);

  const { mutateAsync: upsertChat } = useUpsertChat();
  const { data: chat, isLoading } = useChat(chatId);
  const messageIndex =
    chat?.messages.findIndex((m) => m.id === messageId) ?? -1;
  const message = messageIndex >= 0 ? chat?.messages[messageIndex] : undefined;

  useEffect(() => {
    if (isLoading) return;
    if (message) return;
    replace("ChatList");
  }, [isLoading, message, replace]);

  const actions = useMemo<
    {
      name: string;
      description: string;
      action: (
        chat: Chat,
        message: Message,
        messageIndex: number
      ) => Promise<void>;
      isDestructive?: boolean;
    }[]
  >(
    () => [
      {
        name: "To here",
        description:
          "Create a new chat with all messages up to this point and prefill the input bar with the message. This is great for when you want to edit a message.",
        action: async (chat, message, index) => {
          const id = Date.now().toString();
          await upsertChat({
            ...chat,
            id,
            name: "",
            messages: chat.messages.slice(0, index + 1),
          });
          navigate({ name: "Chat", params: { id, isFork: true }, merge: true });
        },
      },
      {
        name: "From here",
        description:
          "Creates a new chat with all messages starting from the message. This is useful when you want to remove the prior context and create a smaller chat that fits within the token limits. This allows for a more focused and concise conversation.",
        action: async (chat, message, index) => {
          const id = Date.now().toString();
          await upsertChat({
            ...chat,
            id,
            name: "",
            messages: chat.messages.slice(index),
          });
          navigate({ name: "Chat", params: { id }, merge: true });
        },
      },
      {
        name: "Delete",
        isDestructive: true,
        description:
          "Deletes the message. Please note that this action cannot be undone, so proceed with caution.",
        action: async (chat, message) => {
          upsertChat({
            ...chat,
            messages: chat.messages.filter((m) => m.id !== message.id),
          });
          navigate("Chat", { id: chat.id });
        },
      },
    ],
    [navigate, upsertChat]
  );

  return (
    <ScrollView
      contentContainerStyle={{ gap: theme.spacing.m, padding: theme.spacing.m }}
    >
      {actions.map((o, i) => (
        <TouchableOpacity
          key={i}
          onPress={async () => {
            if (!message) return;
            if (!chat) return;
            await o.action(chat, message, messageIndex);
          }}
          style={{
            backgroundColor: theme.colors.surface,
            borderRadius: theme.border.radius.m,
            padding: theme.spacing.m,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
          }}
        >
          <Text
            style={[theme.typography.h2, { color: theme.colors.onSurface }]}
          >
            {o.name}
          </Text>
          <Text
            style={[theme.typography.body1, { color: theme.colors.onSurface }]}
          >
            {o.description}
          </Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};
