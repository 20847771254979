import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useMemo } from "react";
import { z } from "zod";
import {
  AiModel,
  AiRequestInput,
  AiRequestStatus,
  AiRequestType,
  ChatMessageInput,
  ChatMessageRole,
  DataSourceInput,
  DataSourceType,
  useCreateAiRequestAsyncMutation,
  useCreateAiRequestMutation,
  useDeleteAiRequestMutation,
} from "../generated/gen-graphql";
import { useLocalStorage } from "../providers/LocalStorage";
import { isUrl } from "../utils/string";
import { DocSet } from "./Docs";
import { Settings, useSettings } from "./Settings";

const MessageSchema = z.object({
  id: z.string(),
  isPending: z.boolean().optional(),
  date: z.coerce.date(),
  from: z.enum(["me", "bot"]),
  text: z.string(),
});
export type Message = z.infer<typeof MessageSchema>;

const ChatTypeSchema = z.enum(["turbo", "turbo_large", "slow"]);
export type ChatType = z.infer<typeof ChatTypeSchema>;

export const ChatTypeMetadata: {
  type: ChatType;
  fullName: string;
  shortName: string;
  description: string;
  placeholder: string;
}[] = [
  {
    type: "turbo",
    fullName: "Turbo",
    shortName: "Turbo",
    description:
      "Turbo is the fastest and most cost-effective chat model, making it an excellent choice for getting started.",
    placeholder: "Type something to get started...",
  },
  {
    type: "turbo_large",
    fullName: "Turbo Large",
    shortName: "TurboLg",
    description:
      "Turbo Large offers the same speed and quality as Turbo, but with a larger token window. It is slightly more expensive and should only be used if you require the additional tokens.",
    placeholder: "Type something to get started...",
  },
  {
    type: "slow",
    fullName: "Slow and Steady",
    shortName: "Slow",
    description:
      "This chat model is the slowest and most expensive, but it delivers higher quality results compared to Turbo.",
    placeholder: "Type something to get started...",
  },
];

const ChatSchema = z.object({
  id: z.string(),
  name: z.string(),
  messages: z.array(MessageSchema),
  type: ChatTypeSchema,
  docSetId: z.string().optional(),
});
export type Chat = z.infer<typeof ChatSchema>;

export const useChats = () => {
  const { get } = useLocalStorage();
  return useQuery(["chats"], async () => {
    try {
      const s = await get("chats");
      return z.array(ChatSchema).parse(JSON.parse(s));
    } catch (e) {
      return [];
    }
  });
};

export const useCreateNewChat = () => {
  const { mutateAsync } = useUpsertChat();
  return useMutation(async (args: { docSetId?: string; type?: ChatType }) => {
    const newChat: Chat = {
      id: Date.now().toString(),
      name: "",
      messages: [],
      type: args.type || "turbo",
      docSetId: args?.docSetId,
    };
    await mutateAsync(newChat);
    return newChat;
  });
};

export const useChat = (chatId: string) => {
  const { data: chats, ...query } = useChats();
  return useMemo(
    () => ({
      data: chats?.find((c) => c.id === chatId),
      ...query,
    }),
    [chatId, chats, query]
  );
};

export const useMakeTitle = () => {
  const { get: settings } = useSettings();
  const { mutateAsync: deleteRequest } = useDeleteAiRequestMutation();
  const { mutateAsync: upsert } = useUpsertChat();
  const { mutateAsync } = useCreateAiRequestMutation();
  const { refetch } = useChats();
  return useMutation(async (chatId: string) => {
    let chats = (await refetch()).data;
    let chat = chats?.find((c) => c.id === chatId);
    if (!chat) throw new Error("No chat");
    const prompt = `
START_TRANSCRIPT
${chat.messages
  .slice(-5)
  .map((m) =>
    `
From: ${m.from}
${m.text}
    `.trim()
  )
  .join("\n\n")
  .slice(0, 500)}
END_TRANSCRIPT

Generate a very short title for the transcript. Respond with only the title.
  `.trim();
    const { accountId, deleteRequests } = await settings();
    const response = await mutateAsync({
      input: {
        accountId,
        type: AiRequestType.Prompt,
        model: AiModel.Gpt_3_5Turbo,
        prompt: {
          type: DataSourceType.Text,
          textContent: prompt,
        },
      },
    });
    const data = response.createAiRequest;
    if (!data) throw new Error("No response");
    const name = data.response?.trim() || "";

    if (deleteRequests) await deleteRequest({ id: data.id });

    chats = (await refetch()).data;
    chat = chats?.find((c) => c.id === chatId);
    if (!chat) throw new Error("No chat");
    await upsert({ ...chat, name });
  });
};

export const useDeleteChat = () => {
  const { refetch } = useChats();
  const queryClient = useQueryClient();
  const { set } = useLocalStorage();
  return useMutation(async (chatId: string) => {
    const { data: chats } = await refetch();
    if (!chats) throw new Error("No chats");
    await set("chats", JSON.stringify(chats.filter((c) => c.id !== chatId)));
    await queryClient.invalidateQueries(["chats"]);
  });
};

export const useSendMessage = () => {
  const { mutateAsync: upsert } = useUpsertChat();
  const { mutateAsync } = useCreateAiRequestAsyncMutation();
  const { get: getSettings } = useSettings();
  return useMutation(
    async ({
      chat,
      docSet,
      message,
    }: {
      chat: Chat;
      message: string;
      docSet?: DocSet;
    }) => {
      const newMessage: Message = {
        id: Date.now().toString(),
        date: new Date(),
        from: "me",
        text: message,
      };
      chat = { ...chat, messages: [...chat.messages, newMessage] };
      await upsert(chat);

      const settings = await getSettings();
      const request = chatToRequest({
        chat,
        settings,
        docSet,
      });

      if (chat !== request.chat) await upsert(request.chat);
      chat = request.chat;

      if (!request.request) return;

      const response = await mutateAsync({ input: request.request });
      const data = response.createAiRequestAsync;
      if (!data) throw new Error("No response");

      if (data.status === AiRequestStatus.Failed) {
        throw new Error(
          `Something went wrong. If your chat is too long, consider using "Turbo Large" or starting a new chat.`
        );
      }

      const botMessage: Message = {
        id: data.id,
        date: new Date(),
        from: "bot",
        isPending: true,
        text: "",
      };
      chat = {
        ...chat,
        messages: [...chat.messages, botMessage],
      };

      await upsert(chat);
    }
  );
};

export const useUpsertChat = () => {
  const { refetch } = useChats();
  const queryClient = useQueryClient();
  const { set } = useLocalStorage();
  return useMutation(
    async (chat: Chat) => {
      let { data: chats } = await refetch();
      if (!chats) chats = [];
      chats = chats.concat();
      const existingChatIndex = chats.findIndex((c) => c.id === chat.id);
      if (existingChatIndex === -1) chats.push(chat);
      else chats[existingChatIndex] = chat;
      await set("chats", JSON.stringify(chats));
      return chat;
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(["chats"]);
      },
    }
  );
};

const TYPE_TO_MODEL = {
  turbo: AiModel.Gpt_3_5Turbo,
  turbo_large: AiModel.Gpt_3_5Turbo_16k,
  slow: AiModel.Gpt_4Turbo,
};

const chatToRequest = ({
  chat,
  settings,
  docSet,
}: {
  chat: Chat;
  settings: Settings;
  docSet?: DocSet;
}): { chat: Chat; request?: AiRequestInput } => {
  const { accountId } = settings;

  if (!docSet)
    return {
      chat,
      request: {
        accountId,
        type: AiRequestType.Prompt,
        model: TYPE_TO_MODEL[chat.type],
        temperature: settings.temperature,
        prompt: {
          type: DataSourceType.Chat,
          chat: [
            {
              role: ChatMessageRole.System,
              content: {
                type: DataSourceType.Text,
                textContent: `The current date and time is ${moment().format(
                  "lll"
                )}`,
              },
            },
            {
              role: ChatMessageRole.User,
              content: {
                type: DataSourceType.Text,
                textContent: settings.aboutUser || "",
              },
            },
            {
              role: ChatMessageRole.User,
              content: {
                type: DataSourceType.Text,
                textContent: settings.responsePreferences
                  ? `
Respond to me with the following preferences:
${settings.responsePreferences}
                `.trim()
                  : "",
              },
            },
            ...chat.messages.map<ChatMessageInput>((m) => ({
              role:
                m.from === "bot"
                  ? ChatMessageRole.Assistant
                  : ChatMessageRole.User,
              content: {
                type: DataSourceType.Text,
                textContent: m.text,
              },
            })),
          ].filter((m) => !!m.content.textContent),
        },
      },
    };

  return {
    chat,
    request: {
      accountId,
      type: AiRequestType.DataSourcePrompt,
      model: TYPE_TO_MODEL[chat.type],
      temperature: settings.temperature,
      dataSource: {
        type: DataSourceType.Array,
        array: docSet.docs.map<DataSourceInput>((doc) =>
          isUrl(doc)
            ? { type: DataSourceType.Url, url: doc }
            : { type: DataSourceType.Text, textContent: doc }
        ),
      },
      maxCharacters: 500,
      prompt: {
        type: DataSourceType.Array,
        array: [
          {
            type: DataSourceType.AiRequest,
            aiRequest: {
              accountId,
              type: AiRequestType.Prompt,
              model: TYPE_TO_MODEL[chat.type],
              prompt: {
                type: DataSourceType.Text,
                textContent: `
START_TRANSCRIPT
${chat.messages
  .map((m) =>
    `
FROM ${m.from}
BODY_START
${m.text}
BODY_END
`.trim()
  )
  .join("\n\n")}
END_TRANSCRIPT

Rewrite ONLY the last message from "me" so it can be submitted as a stand-alone question or statement separate from the rest of the chat.
It will be submitted to somebody that doesn't know anything about the transcript and has no context.
Carry over any information that is relevant to the question or statement so it can be understood without context.
Your response should look something like:
"The user would like to know..."
or
"The user says that..."
              `.trim(),
              },
            },
          },
          {
            type: DataSourceType.Text,
            textContent: `

Respond to the user using ONLY the data sources provided.
If you cannot respond or if there's no specific query, suggest some topics that are in the data sources.
Respond in the style of an informal human.
You do not need to say hi.
            `.trimEnd(),
          },
        ],
      },
    },
  };
};
