import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { FC } from "react";
import { useHeaderTitleStyle } from "../hooks/Theme";
import { useTheme } from "../providers/Theme";
import { ChatListScreen } from "../screens/ChatListScreen";
import { DocSetListScreen } from "../screens/DocSetListScreen";
import { GoogleSearchScreen } from "../screens/GoogleSearchScreen";
import { SettingsScreen } from "../screens/SettingsScreen";

export type TabParamsList = {
  ChatList: undefined;
  DocSets: undefined;
  Google: undefined;
  Settings: undefined;
};

const Tabs = createBottomTabNavigator<TabParamsList>();

export const TabNavigator: FC = () => {
  const headerTitleStyle = useHeaderTitleStyle();
  const theme = useTheme();
  return (
    <Tabs.Navigator
      initialRouteName="ChatList"
      screenOptions={{
        headerTitleStyle,
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: theme.colors.onSurface,
      }}
    >
      <Tabs.Screen
        name="ChatList"
        component={ChatListScreen}
        options={{
          tabBarLabel: "Chat",
          headerTitle: "Chats",
          tabBarIcon: ({ size, color }) => (
            <Ionicons name="chatbubbles" size={size - 4} color={color} />
          ),
        }}
      />
      <Tabs.Screen
        name="DocSets"
        component={DocSetListScreen}
        options={{
          tabBarLabel: "Docs",
          headerTitle: "Document Sets",
          tabBarIcon: ({ size, color }) => (
            <Ionicons name="documents" size={size - 4} color={color} />
          ),
        }}
      />
      <Tabs.Screen
        name="Google"
        component={GoogleSearchScreen}
        options={{
          tabBarLabel: "Google",
          headerTitle: "Google Search",
          tabBarIcon: ({ size, color }) => (
            <Ionicons name="logo-google" size={size - 4} color={color} />
          ),
        }}
      />
      <Tabs.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          tabBarIcon: ({ size, color }) => (
            <Ionicons name="settings" size={size - 4} color={color} />
          ),
        }}
      />
    </Tabs.Navigator>
  );
};
