import { Ionicons } from "@expo/vector-icons";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { FC, useEffect, useLayoutEffect } from "react";
import {
  ActivityIndicator,
  Button,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { HeaderBackButton } from "../components/HeaderButton";
import { ChatTypeMetadata, useChat, useUpsertChat } from "../hooks/Chats";
import { useDocSet } from "../hooks/Docs";
import { RootParamsList } from "../navigators/RootNavigator";
import { TabParamsList } from "../navigators/TabNavigator";
import { useTheme } from "../providers/Theme";
import { showAlert } from "../utils/string";

export const EditChatTypeScreen: FC = () => {
  const theme = useTheme();
  const { setOptions, replace, navigate } =
    useNavigation<
      CompositeNavigationProp<
        NativeStackNavigationProp<RootParamsList>,
        NativeStackNavigationProp<TabParamsList>
      >
    >();

  const route = useRoute<RouteProp<RootParamsList, "EditChatType">>();
  const { chatId } = route.params;
  const { data: chat, isLoading } = useChat(chatId);

  useEffect(() => {
    if (isLoading) return;
    if (chat) return;
    replace("ChatList");
  }, [chat, isLoading, replace]);

  useLayoutEffect(() => {
    setOptions({
      headerLeft: () => (
        <HeaderBackButton
          isClose
          fallback={{ name: "Chat", params: { id: chatId } }}
        />
      ),
    });
  }, [chatId, setOptions]);

  const { mutateAsync } = useUpsertChat();
  const { data: docSet, isLoading: isDocsLoading } = useDocSet(chat?.docSetId);

  const { bottom } = useSafeAreaInsets();

  return !chat || (chat.docSetId && isDocsLoading) ? (
    <ActivityIndicator color={theme.colors.primary} />
  ) : (
    <ScrollView
      contentContainerStyle={{
        gap: theme.spacing.m,
        padding: theme.spacing.m,
        paddingBottom: bottom + theme.spacing.m,
      }}
    >
      {ChatTypeMetadata.map((o, i) => (
        <TouchableOpacity
          key={i}
          onPress={async () => {
            if (!chat) return;
            await mutateAsync({ ...chat, type: o.type });
            navigate("Chat", { id: chatId });
          }}
          style={{
            backgroundColor: theme.colors.surface,
            borderRadius: theme.border.radius.m,
            padding: theme.spacing.m,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
          }}
        >
          <Text
            style={[theme.typography.h2, { color: theme.colors.onSurface }]}
          >
            {o.fullName}
          </Text>
          <Text
            style={[theme.typography.body1, { color: theme.colors.onSurface }]}
          >
            {o.description}
          </Text>
        </TouchableOpacity>
      ))}
      <View
        style={{
          backgroundColor: theme.colors.surface,
          borderRadius: theme.border.radius.m,
          padding: theme.spacing.m,
          borderColor: theme.colors.border,
          borderWidth: theme.border.width.s,
        }}
      >
        <Text style={[theme.typography.h2, { color: theme.colors.onSurface }]}>
          Doc set
        </Text>
        <Text
          style={[
            theme.typography.body1,
            {
              color: theme.colors.onSurface,

              marginBottom: theme.spacing.m,
            },
          ]}
        >
          Use AI to chat about a specific document set. It will only respond
          using information from the documents.
        </Text>
        {docSet && (
          <View
            style={{
              flexDirection: "row",
              marginBottom: theme.spacing.m,
              alignItems: "center",
            }}
          >
            <Text
              style={[
                theme.typography.body1,
                { color: theme.colors.onSurface, flex: 1 },
              ]}
            >
              Selected: {docSet.name || "(unnamed)"}
            </Text>
            <Ionicons.Button
              name="remove"
              size={18}
              color={theme.colors.onError}
              backgroundColor={theme.colors.error}
              iconStyle={{ marginRight: 0 }}
              onPress={() => {
                mutateAsync({ ...chat, docSetId: undefined });
              }}
            />
          </View>
        )}
        <Button
          title={docSet ? "Change" : "Select"}
          color={theme.colors.primary}
          onPress={() =>
            showAlert(
              "This has not been implemented yet.",
              "You can start a chat with a doc set from the button at the top right of the doc set screen"
            )
          }
        />
      </View>
    </ScrollView>
  );
};
