import { useIsFocused, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import moment from "moment";
import { FC, useEffect, useLayoutEffect, useMemo } from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Swipeable, TouchableHighlight } from "react-native-gesture-handler";
import { EmptyScreen } from "../components/EmptyScreen";
import { HeaderIconButton } from "../components/HeaderButton";
import { useCreateNewDocSet, useDeleteDocSet, useDocSets } from "../hooks/Docs";
import { RootParamsList } from "../navigators/RootNavigator";
import { useTheme } from "../providers/Theme";

export const DocSetListScreen: FC = () => {
  const { mutate: del } = useDeleteDocSet();

  const { data } = useDocSets();
  const { mutateAsync } = useCreateNewDocSet();

  const sortedData = useMemo(() => {
    if (!data) return data;
    return data
      .concat()
      .sort((a, b) => moment(b.lastModified).diff(a.lastModified));
  }, [data]);

  const { setOptions, navigate } =
    useNavigation<NativeStackNavigationProp<RootParamsList>>();

  const theme = useTheme();

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) return;
    let abort = false;
    (async () => {
      for (const o of data || []) {
        if (o.docs.length) continue;
        if (o.name) continue;
        await del(o.id);
        if (abort) return;
      }
    })();

    return () => {
      abort = true;
    };
  }, [data, del, isFocused]);

  useLayoutEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderIconButton
          isTab
          name="md-add"
          onPress={async () => {
            const chat = await mutateAsync();
            navigate("DocSet", { id: chat.id });
          }}
        />
      ),
    });
  }, [mutateAsync, navigate, setOptions, theme]);

  return (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={sortedData}
      ListEmptyComponent={() =>
        !sortedData ? (
          <ActivityIndicator color={theme.colors.primary} />
        ) : (
          <EmptyScreen
            title="No Document Sets"
            subtitle={[
              "You can easily summarize or ask questions about a set of documents.",
              "Create one using the create button at the top right to get started.",
            ].join("\n")}
          />
        )
      }
      renderItem={({ item }) => (
        <Swipeable
          renderRightActions={() => (
            <TouchableOpacity
              onPress={() => del(item.id)}
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: theme.spacing.l,
                backgroundColor: theme.colors.error,
                height: "100%",
              }}
            >
              <Text
                style={[
                  { color: theme.colors.onSecondary },
                  theme.typography.button,
                ]}
              >
                Delete
              </Text>
            </TouchableOpacity>
          )}
        >
          <TouchableHighlight
            onPress={() => navigate("DocSet", { id: item.id })}
          >
            <View
              style={{
                backgroundColor: theme.colors.surface,
                padding: theme.spacing.m,
                borderBottomWidth: theme.border.width.s,
                borderBottomColor: theme.colors.border,
                flexDirection: "row",
                gap: theme.spacing.xs,
                width: "100%",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  numberOfLines={1}
                  style={[
                    theme.typography.subtitle1,
                    { color: theme.colors.onSurface },
                  ]}
                >
                  {item.name || "Unnamed"}
                </Text>
                <Text
                  style={[
                    theme.typography.caption,
                    {
                      color: theme.colors.onSurface,
                      marginTop: theme.spacing.xs,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {item.docs.length} doc{item.docs.length === 1 ? "" : "s"}
                </Text>
              </View>
              <View style={{ alignItems: "flex-end" }}>
                {
                  <>
                    <Text
                      style={[
                        theme.typography.caption,
                        { flex: 0, color: theme.colors.onSurface },
                      ]}
                    >
                      {moment(item.lastModified).format("l")}
                    </Text>
                    <Text
                      style={[
                        theme.typography.caption,
                        { flex: 0, color: theme.colors.onSurface },
                      ]}
                    >
                      {moment(item.lastModified).format("LT")}
                    </Text>
                  </>
                }
              </View>
            </View>
          </TouchableHighlight>
        </Swipeable>
      )}
    />
  );
};
