import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { FC, useLayoutEffect } from "react";
import { ScrollView, Text, TouchableOpacity } from "react-native";
import { HeaderBackButton } from "../components/HeaderButton";
import { DocSetTypeMetadata } from "../hooks/Docs";
import { RootParamsList } from "../navigators/RootNavigator";
import { TabParamsList } from "../navigators/TabNavigator";
import { useTheme } from "../providers/Theme";

export const EditDocSetTypeScreen: FC = () => {
  const theme = useTheme();
  const { setOptions, goBack } =
    useNavigation<
      CompositeNavigationProp<
        NativeStackNavigationProp<RootParamsList>,
        NativeStackNavigationProp<TabParamsList>
      >
    >();

  const route = useRoute<RouteProp<RootParamsList, "EditDocSetType">>();
  const { callback } = route.params;

  useLayoutEffect(() => {
    setOptions({
      headerLeft: () => <HeaderBackButton isClose fallback={"DocSets"} />,
    });
  }, [setOptions]);

  return (
    <ScrollView
      contentContainerStyle={{ gap: theme.spacing.m, padding: theme.spacing.m }}
    >
      {DocSetTypeMetadata.map((o, i) => (
        <TouchableOpacity
          key={i}
          onPress={async () => {
            callback(o.type);
            goBack();
          }}
          style={{
            backgroundColor: theme.colors.surface,
            borderRadius: theme.border.radius.m,
            padding: theme.spacing.m,
            borderColor: theme.colors.border,
            borderWidth: theme.border.width.s,
          }}
        >
          <Text
            style={[theme.typography.h2, { color: theme.colors.onSurface }]}
          >
            {o.name}
          </Text>
          <Text
            style={[theme.typography.body1, { color: theme.colors.onSurface }]}
          >
            {o.description}
          </Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};
