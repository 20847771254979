import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const DEFAULT_DEBOUNCE_MS = 100;
export function useDebounce(
  fn: EffectCallback,
  deps: DependencyList,
  delayMs = DEFAULT_DEBOUNCE_MS,
) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const delayRef = useRef(delayMs);
  delayRef.current = delayMs;

  useEffect(() => {
    let abort = false;
    let destructor: void | (() => void);
    setTimeout(() => {
      if (abort) return;
      destructor && destructor();
      destructor = fnRef.current?.();
    }, delayRef.current);
    return () => {
      destructor && destructor();
      abort = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
