import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { FC } from "react";
import { Button, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useTheme } from "../providers/Theme";
import { isIOS } from "../utils/device";

export const HeaderIconButton: FC<{
  onPress: () => void;
  name: keyof (typeof Ionicons)["glyphMap"];
  isTab?: boolean;
  disabled?: boolean;
}> = ({ onPress, name, disabled, isTab }) => {
  const theme = useTheme();
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={disabled ? undefined : onPress}
      style={{
        marginHorizontal: !isIOS() || isTab ? theme.spacing.m : 0,
      }}
    >
      <Ionicons
        size={24}
        color={theme.colors.primary}
        name={name}
        style={{ opacity: disabled ? 0.5 : 1 }}
      />
    </TouchableOpacity>
  );
};

export const HeaderButton: FC<{
  title: string;
  onPress: () => void;
  isTab?: boolean;
  disabled?: boolean;
}> = ({ onPress, title, disabled, isTab }) => {
  const theme = useTheme();
  return (
    <View style={{ marginHorizontal: !isIOS() || isTab ? theme.spacing.s : 0 }}>
      <Button
        disabled={disabled}
        title={title}
        onPress={onPress}
        color={theme.colors.primary}
      />
    </View>
  );
};

export const HeaderBackButton = ({
  fallback,
  isClose,
}: {
  fallback: unknown;
  isClose?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { navigate, canGoBack, goBack } = useNavigation<any>();
  return (
    <HeaderIconButton
      name={isClose ? "close" : "arrow-back"}
      onPress={() => {
        if (canGoBack()) goBack();
        else typeof fallback === "function" ? fallback() : navigate(fallback);
      }}
    />
  );
};
