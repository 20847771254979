import { z } from "zod";
import { ErrorType } from "../generated/gen-graphql";

const ClientErrorSchema = z.object({
  type: z.nativeEnum(ErrorType),
  userMessage: z.string().optional(),
});
const ClientErrorSchemaArray = z.array(ClientErrorSchema);

const GqlErrorSchema = z.object({
  message: z.string().optional(),
  extensions: z.object({
    code: z.nativeEnum(ErrorType),
    message: z.string().optional(),
  }),
});
const GqlErrorSchemaArray = z.array(GqlErrorSchema);

export type ClientError = z.infer<typeof ClientErrorSchema>;

export const toClientErrors = (error: unknown): ClientError[] => {
  try {
    const gqlErrors = GqlErrorSchemaArray.parse(error);
    return gqlErrors.map((gqlError) => ({
      type: gqlError.extensions.code,
      userMessage: gqlError.message || gqlError.extensions.message,
    }));
  } catch (e) {
    // pass
  }

  try {
    return ClientErrorSchemaArray.parse(error);
  } catch (e) {
    // pass
  }
  try {
    return [ClientErrorSchema.parse(error)];
  } catch (e) {
    // pass
  }
  console.error("Unexpected error", error);
  return [{ type: ErrorType.UnexpectedError }];
};

const ErrorTypeToTitle: Record<ErrorType, string> = {
  [ErrorType.InsufficientBalance]: "Insufficient Balance",
  [ErrorType.ScrapeFailure]: "Scrape Failure",
  [ErrorType.AiRequestSizeError]: "Too Many Tokens",
  [ErrorType.UnexpectedError]: "Unexpected Error",
  [ErrorType.AuthenticationFailure]: "Authentication Failure",
  [ErrorType.InvalidEmailVerificationCode]: "Invalid Email Verification Code",
  [ErrorType.UserInputValidationFailure]: "User Input Validation Failure",
  [ErrorType.AuthorizationFailure]: "Authorization Failure",
};

export const exceptionToToast = (e: ClientError) => {
  return {
    title: ErrorTypeToTitle[e.type],
    description: e.userMessage,
  };
};
