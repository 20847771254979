import { Audio, InterruptionModeIOS } from "expo-av";
import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import * as Speech from "expo-speech";
import memoize from "lodash.memoize";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppState } from "react-native";
import { isAndroid, isWeb } from "../utils/device";

const getSilenceHack = memoize(() => {
  if (isWeb() || isAndroid())
    return {
      start: async () => {},
      stop: async () => {},
    };

  const init = memoize(async () => {
    await Audio.setAudioModeAsync({
      interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
      playsInSilentModeIOS: true,
      staysActiveInBackground: true,
    });

    const sound = new Audio.Sound();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    await sound.loadAsync(require("../assets/silence.m4a"));
    await sound.setIsLoopingAsync(true);
    return sound;
  });

  return {
    start: async () => {
      const sound = await init();
      await Audio.setAudioModeAsync({
        interruptionModeIOS: InterruptionModeIOS.DuckOthers,
        playsInSilentModeIOS: true,
      });
      await sound.playAsync();
    },
    stop: async () => {
      const sound = await init();
      await Audio.setAudioModeAsync({
        interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
        playsInSilentModeIOS: true,
      });
      await sound.stopAsync();
    },
  };
});

type Context = {
  say: (uri: string) => void;
  currentlySpeaking: string;
};

export const SpeechContext = createContext<Context>({
  say: async () => Date.now().toString(),
  currentlySpeaking: "",
});

export const useSpeech = () => useContext(SpeechContext);

export const SpeechProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentlySpeaking, setCurrentlySpeaking] = useState("");

  useEffect(() => {
    if (!currentlySpeaking) return;
    activateKeepAwakeAsync();
    return () => {
      deactivateKeepAwake();
    };
  }, [currentlySpeaking]);

  useEffect(() => {
    if (isWeb()) return;
    const detach = AppState.addEventListener("change", (state) => {
      if (state === "active") return;
      setCurrentlySpeaking("");
    });
    return () => {
      detach.remove();
    };
  }, []);

  useEffect(() => {
    if (!currentlySpeaking) getSilenceHack().stop();
    else getSilenceHack().start();
  }, [currentlySpeaking]);

  useEffect(() => {
    Speech.pause();
    Speech.stop();
    if (!currentlySpeaking) return;

    let abort = false;

    const interval = 100;
    Speech.speak(currentlySpeaking);
    const fn = async () => {
      if (abort) return;
      const isSpeaking = await Speech.isSpeakingAsync();
      if (abort) return;
      if (isSpeaking) setTimeout(fn, interval);
      else setCurrentlySpeaking("");
    };
    setTimeout(fn, interval);

    return () => {
      abort = true;
    };
  }, [currentlySpeaking]);

  const value = useMemo<Context>(
    () => ({
      say: (s) => setCurrentlySpeaking(s),
      currentlySpeaking,
    }),
    [currentlySpeaking]
  );

  return (
    <SpeechContext.Provider value={value}>{children}</SpeechContext.Provider>
  );
};
