import { Ionicons } from "@expo/vector-icons";
import * as Clipboard from "expo-clipboard";
import { FC } from "react";
import { StyleProp, Text, View, ViewStyle } from "react-native";
import Markdown from "react-native-markdown-display";
import { useSpeech } from "../providers/Speech";
import { useTheme } from "../providers/Theme";

export const AiMarkdown: FC<{
  markdown: string;
  textColor: string;
  style?: StyleProp<ViewStyle>;
}> = ({ markdown, textColor, style }) => {
  const theme = useTheme();
  const { currentlySpeaking, say } = useSpeech();

  return (
    <View style={[{ position: "relative" }, style]}>
      <Markdown
        rules={{
          fence: (node, children, parent, styles, inheritedStyles = {}) => {
            // we trim new lines off the end of code blocks because the parser sends an extra one.
            let { content } = node;

            if (
              typeof node.content === "string" &&
              node.content.charAt(node.content.length - 1) === "\n"
            ) {
              content = node.content.substring(0, node.content.length - 1);
            }

            return (
              <View style={{ position: "relative" }} key={node.key}>
                <View
                  style={{
                    position: "absolute",
                    top: theme.spacing.s,
                    right: theme.spacing.s,
                    left: 0,
                    zIndex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Ionicons.Button
                    name="copy"
                    iconStyle={{ marginRight: 0 }}
                    color={theme.colors.primary}
                    backgroundColor={"transparent"}
                    onPress={() => Clipboard.setStringAsync(content)}
                  />
                </View>
                <Text style={[inheritedStyles, styles.fence]}>{content}</Text>
              </View>
            );
          },
        }}
        style={{
          body: { color: textColor, ...theme.typography.body1 },
          table: { borderColor: theme.colors.border },
          tbody: { borderColor: theme.colors.border },
          td: { borderColor: theme.colors.border },
          th: { borderColor: theme.colors.border },
          thead: { borderColor: theme.colors.border },
          tr: { borderColor: theme.colors.border },
          blockquote: {
            backgroundColor: theme.colors.background,
            color: theme.colors.onBackground,
          },
          code_block: {
            backgroundColor: theme.colors.background,
            color: theme.colors.onBackground,
            fontFamily: "Courier New",
          },
          code_inline: {
            backgroundColor: theme.colors.background,
            color: theme.colors.onBackground,
            fontFamily: "Courier New",
            paddingVertical: 0,
            paddingHorizontal: theme.spacing.xs,
          },
          fence: {
            backgroundColor: theme.colors.background,
            color: theme.colors.onBackground,
            fontFamily: "Courier New",
            padding: theme.spacing.m,
          },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/no-children-prop
        children={markdown}
      />
      <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
        {currentlySpeaking === markdown ? (
          <Ionicons.Button
            name={"stop"}
            color={theme.colors.error}
            backgroundColor={"transparent"}
            onPress={() => say("")}
            iconStyle={{ marginRight: 0 }}
          />
        ) : (
          <Ionicons.Button
            name={"play"}
            color={theme.colors.secondary}
            backgroundColor={"transparent"}
            onPress={() => say(markdown)}
            iconStyle={{ marginRight: 0 }}
          />
        )}
        <Ionicons.Button
          name="copy"
          color={theme.colors.primary}
          backgroundColor={"transparent"}
          onPress={() => Clipboard.setStringAsync(markdown)}
          iconStyle={{ marginRight: 0 }}
        />
      </View>
    </View>
  );
};
