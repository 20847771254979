import { Alert } from "react-native";
import { isAndroid, isWeb } from "./device";

export const isUrl = (s: string) => {
  return s.match(/^https?:\/\/./i);
};

const MAX_ANDROID = 20;
export const toAndroidHeaderTitle = (s: string) => {
  return s.length <= MAX_ANDROID || !isAndroid()
    ? s
    : s.substring(0, MAX_ANDROID) + "...";
};

export const confirmAction = ({
  title,
  message,
  onConfirm,
}: {
  title: string;
  message?: string;
  onConfirm: () => void;
}) => {
  if (!isWeb()) {
    Alert.alert(title, message, [
      { text: "Cancel", style: "cancel" },
      { text: "Confirm", style: "destructive", onPress: onConfirm },
    ]);
  } else if (confirm([title, message].filter((s) => !!s).join("\n"))) {
    onConfirm();
  }
};

export const showAlert = (title: string, message?: string) => {
  if (!isWeb()) {
    Alert.alert(title, message);
  } else {
    alert([title, message].filter((s) => !!s).join("\n"));
  }
};
