import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import isEqual from "lodash.isequal";
import moment from "moment";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TextInput,
  View,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AiMarkdown } from "../components/AiMarkdown";
import { HeaderBackButton, HeaderButton } from "../components/HeaderButton";
import { KeyboardAvoidingView } from "../components/KeyboardAvoidingView";
import {
  AiRequestStatus,
  useAiRequestsQuery,
  useDeleteAiRequestMutation,
} from "../generated/gen-graphql";
import {
  ChatTypeMetadata,
  Message,
  useChat,
  useMakeTitle,
  useSendMessage,
  useUpsertChat,
} from "../hooks/Chats";
import { useDocSet } from "../hooks/Docs";
import { usePromise } from "../hooks/Promise";
import { useEnsureApiKey, useSettings } from "../hooks/Settings";
import { RootParamsList } from "../navigators/RootNavigator";
import { useTheme } from "../providers/Theme";
import { isWeb } from "../utils/device";
import { exceptionToToast, toClientErrors } from "../utils/error";
import { showAlert, toAndroidHeaderTitle } from "../utils/string";

export const ChatScreen: FC = () => {
  const theme = useTheme();
  const { bottom } = useSafeAreaInsets();

  const { setOptions, navigate, setParams } =
    useNavigation<NativeStackNavigationProp<RootParamsList, "Chat">>();

  const { id, isFork } = useRoute<RouteProp<RootParamsList, "Chat">>().params;
  const { data: chat, isLoading: isChatLoading } = useChat(id);
  const { data: docSet, isLoading: isDocSetLoading } = useDocSet(
    chat?.docSetId
  );
  const { mutate: upsertChat, isLoading: isUpdating } = useUpsertChat();

  useEffect(() => {
    if (!chat?.docSetId) return;
    if (isDocSetLoading) return;
    if (docSet) return;
    showAlert(
      "Error",
      "This chat's doc set was not found. Reverting to a normal chat."
    );
    upsertChat({ ...chat, docSetId: undefined });
  }, [chat, docSet, isDocSetLoading, upsertChat]);

  useEffect(() => {
    if (isChatLoading) return;
    if (chat) return;
    navigate("Tabs");
  }, [chat, isChatLoading, navigate]);

  const chatTypeMeta = useMemo(() => {
    return ChatTypeMetadata.find((o) => o.type === chat?.type);
  }, [chat?.type]);

  useEnsureApiKey();

  useLayoutEffect(() => {
    if (!chat) return;
    setOptions({
      title: toAndroidHeaderTitle(chat.name),
      headerLeft: () => <HeaderBackButton fallback={"ChatList"} />,
      headerRight: () => (
        <HeaderButton
          title={chatTypeMeta?.shortName ?? ""}
          onPress={() => {
            navigate("EditChatType", { chatId: chat.id });
          }}
        />
      ),
    });
  }, [chat, chatTypeMeta?.shortName, navigate, setOptions]);

  const [message, setMessage] = useState("");

  useLayoutEffect(() => {
    if (isUpdating) return;
    if (!isFork) return;
    if (!chat) return;
    const messages = chat.messages.concat();
    const lastMessage = messages.pop();
    if (!lastMessage) return;
    setParams({ isFork: undefined, id: chat.id });
    setMessage(lastMessage.text);
    upsertChat({ ...chat, messages });
  }, [chat, isFork, isUpdating, setParams, upsertChat]);

  const { mutate, error, isLoading } = useSendMessage();
  const pendingMessageIds = useMemo(() => {
    if (!chat) return [];
    return chat.messages.filter((o) => o.isPending).map((o) => o.id);
  }, [chat]);
  const { data: pendingRequestsData } = useAiRequestsQuery(
    { input: { ids: pendingMessageIds } },
    { enabled: !!pendingMessageIds.length, refetchInterval: 500 }
  );
  const { mutate: deleteAiRequest } = useDeleteAiRequestMutation();
  const { get } = useSettings();
  const [settingsPromise] = useState(() => get());
  const { value: settings } = usePromise(settingsPromise);

  useEffect(() => {
    if (!settings) return;
    const pendingRequests = pendingRequestsData?.aiRequests;
    if (!pendingRequests) return;
    if (!chat) return;

    const newMessages = chat.messages.map((message) => {
      if (!message.isPending) return message;
      const pendingRequest = pendingRequests.find((o) => o.id === message.id);
      if (!pendingRequest) return message;
      if (
        pendingRequest.status !== AiRequestStatus.Completed &&
        pendingRequest.status !== AiRequestStatus.Failed
      )
        return message;

      if (settings.deleteRequests) deleteAiRequest({ id: pendingRequest.id });

      const newMessage = {
        ...message,
        text: pendingRequest.response ?? "Something went wrong.",
      };
      delete newMessage.isPending;
      return newMessage;
    });

    if (isEqual(newMessages, chat.messages)) return;
    upsertChat({ ...chat, messages: newMessages });
  }, [
    chat,
    deleteAiRequest,
    pendingRequestsData?.aiRequests,
    settings,
    upsertChat,
  ]);

  const {
    mutate: makeTitle,
    isLoading: isMakingTitle,
    error: errorMakingTitle,
  } = useMakeTitle();
  useEffect(() => {
    if (errorMakingTitle) return;
    if (isMakingTitle) return;
    if (!chat) return;
    if (chat.name) return;
    if (chat.messages.length < 2) return;
    makeTitle(chat.id);
  }, [chat, errorMakingTitle, isMakingTitle, makeTitle]);

  useEffect(() => {
    if (!error) return;
    const clientErrors = toClientErrors(error);
    const toast = exceptionToToast(clientErrors[0]);
    showAlert(toast.title, toast.description);
  }, [error]);

  const flatListRef = useRef<FlatList>(null);
  const isSendable = !isLoading && message.trim();
  const maybeSendMessage = () => {
    if (!chat) return;
    if (!isSendable) return;
    mutate({ chat, message, docSet });
    flatListRef.current?.scrollToOffset({ offset: 0 });
    setMessage("");
    setInputHeight(undefined);
    textInputRef.current?.focus();
  };

  const messages = useMemo(() => {
    return chat?.messages.concat().reverse();
  }, [chat?.messages]);

  const [inputHeight, setInputHeight] = useState<number>();

  const textInputRef = useRef<TextInput>(null);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) return;
    if (!isWeb()) return;
    textInputRef.current?.focus();
  }, [isFocused]);

  const chatMessages = useMemo(
    () =>
      theme &&
      messages && (
        <FlatList
          keyboardDismissMode="on-drag"
          ref={flatListRef}
          inverted={messages.length > 0}
          contentContainerStyle={{ flexGrow: 1 }}
          data={messages}
          ListHeaderComponent={
            isLoading
              ? () => (
                  <View
                    style={{
                      flex: 1,
                      padding: theme.spacing.l,
                      backgroundColor: theme.colors.surface,
                    }}
                  >
                    <ActivityIndicator color={theme.colors.primary} />
                  </View>
                )
              : undefined
          }
          ListEmptyComponent={() => (
            <View
              style={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={[
                  theme.typography.h2,
                  { color: theme.colors.onBackground },
                ]}
              >
                No messages
              </Text>
            </View>
          )}
          renderItem={({ item: message, index }) => {
            return (
              <MessageView
                key={message.id}
                message={message}
                onMore={() => {
                  if (!chat) return;
                  navigate("MessageOptions", {
                    chatId: chat.id,
                    messageId: message.id,
                  });
                }}
              />
            );
          }}
        />
      ),
    [chat, isLoading, messages, navigate, theme]
  );

  return !messages ? (
    <ActivityIndicator color={theme.colors.primary} />
  ) : (
    <KeyboardAvoidingView style={{ height: "100%" }}>
      {chatMessages}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing.xs,
          padding: theme.spacing.s,
          marginBottom: bottom,
          borderTopColor: theme.colors.border,
          borderTopWidth: theme.border.width.s,
        }}
      >
        <View
          style={{
            flex: 1,
            borderWidth: theme.border.width.s,
            borderColor: theme.colors.border,
            borderRadius: theme.border.radius.s,
            backgroundColor: theme.colors.surface,
            padding: theme.spacing.s,
          }}
        >
          <TextInput
            ref={textInputRef}
            placeholder={chatTypeMeta?.placeholder}
            placeholderTextColor={theme.colors.onSurface + "80"}
            autoFocus={isWeb()}
            value={message}
            onChangeText={setMessage}
            onKeyPress={(e) => {
              if (!isWeb()) return;
              if (
                e.nativeEvent.key === "Enter" &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                !(e.nativeEvent as any).shiftKey
              ) {
                maybeSendMessage();
                e.preventDefault();
              }
            }}
            multiline
            onContentSizeChange={(event) => {
              if (!isWeb()) return;
              setInputHeight(event.nativeEvent.contentSize.height);
            }}
            style={{
              color: theme.colors.onSurface,
              ...(isWeb() && { outlineStyle: "none" }),
              height: inputHeight,
            }}
          />
        </View>
        <Ionicons.Button
          name="send"
          disabled={!isSendable}
          iconStyle={{ marginRight: 0, opacity: !isSendable ? 0.5 : 1 }}
          backgroundColor={theme.colors.primary}
          color={theme.colors.onPrimary}
          onPress={maybeSendMessage}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

const MessageView: FC<{
  message: Message;
  onMore: () => void;
}> = ({ message, onMore }) => {
  const theme = useTheme();
  const backgroundColor =
    message.from === "me" ? theme.colors.background : theme.colors.surface;
  const color =
    message.from === "me" ? theme.colors.onBackground : theme.colors.onSurface;
  return (
    <View
      style={{
        padding: theme.spacing.m,
        backgroundColor,
        borderTopWidth: theme.border.width.s,
        borderTopColor: theme.colors.border,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          gap: theme.spacing.s,
          alignItems: "center",
        }}
      >
        <View style={{ flex: 1 }}>
          <FontAwesome5
            name={message.from === "me" ? "user-alt" : "robot"}
            size={16}
            color={
              message.from === "me"
                ? theme.colors.secondary
                : theme.colors.primary
            }
          />
        </View>
        <TouchableOpacity
          style={{ paddingLeft: theme.spacing.m }}
          onPress={onMore}
        >
          <MaterialIcons
            size={24}
            name="more-vert"
            color={theme.colors.secondary}
            backgroundColor={backgroundColor}
          />
        </TouchableOpacity>
      </View>
      {message.isPending ? (
        <ActivityIndicator color={theme.colors.primary} />
      ) : (
        <AiMarkdown
          style={{ marginTop: theme.spacing.s }}
          textColor={color}
          markdown={message.text}
        />
      )}
      <Text
        style={[
          { width: "100%", textAlign: "right", color },
          theme.typography.caption,
        ]}
      >
        {moment(message.date).fromNow()}
      </Text>
    </View>
  );
};
