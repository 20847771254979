import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useMemo } from "react";
import { z } from "zod";
import {
  AiModel,
  AiRequestType,
  DataSourceInput,
  DataSourceType,
  useCreateAiRequestAsyncMutation,
  useCreateAiRequestMutation,
  useDeleteAiRequestMutation,
} from "../generated/gen-graphql";
import { useLocalStorage } from "../providers/LocalStorage";
import { isUrl } from "../utils/string";
import { useSettings } from "./Settings";

const DocSetTypeSchema = z.enum(["summary", "facts"]);
export type DocSetType = z.infer<typeof DocSetTypeSchema>;

export const DocSetTypeMetadata: {
  type: DocSetType;
  name: string;
  promptPlaceholder: string;
  description: string;
}[] = [
  {
    type: "summary",
    name: "Summarize as Paragraph",
    description: "Summarize all docs into the specified number of characters.",
    promptPlaceholder:
      "An optional guide for the summary. Leave blank for a generic summary.",
  },
  {
    type: "facts",
    name: "Summarize as Bullet Points",
    description:
      "Outputs all key facts from the docs and outputs bullet points.",
    promptPlaceholder:
      "An optional guide for the facts. Leave blank for generic facts.",
  },
];

const DocSetSchema = z.object({
  id: z.string(),
  name: z.string(),
  docs: z.array(z.string()),
  lastModified: z.coerce.date(),
  // Search was removed in favor of chat. This is to maintain backwards compatibility.
  type: z
    .string()
    .transform((s) => (s === "search" ? "summary" : s))
    .pipe(DocSetTypeSchema),
  prompt: z.string(),
  maxCharacters: z.number(),
  pendingResponseId: z.string().optional(),
  response: z.string().optional(),
});
export type DocSet = z.infer<typeof DocSetSchema>;

const KEY = "docSets";

export const useDocSets = () => {
  const { get } = useLocalStorage();
  return useQuery([KEY], async () => {
    try {
      const s = await get(KEY);
      return z.array(DocSetSchema).parse(JSON.parse(s));
    } catch (e) {
      return [];
    }
  });
};

export const useCreateNewDocSet = () => {
  const { mutateAsync } = useUpsertDoc();
  return useMutation(async () => {
    const o: DocSet = {
      id: Date.now().toString(),
      name: "",
      docs: [],
      lastModified: new Date(),
      type: "summary",
      prompt: "",
      maxCharacters: 0,
    };
    await mutateAsync(o);
    return o;
  });
};

export const useDocSet = (id?: string) => {
  const { data, ...query } = useDocSets();
  return useMemo(
    () => ({ data: data?.find((c) => c.id === id), ...query }),
    [id, data, query]
  );
};

export const useDeleteDocSet = () => {
  const { refetch } = useDocSets();
  const queryClient = useQueryClient();
  const { set } = useLocalStorage();
  return useMutation(async (id: string) => {
    const { data } = await refetch();
    if (!data) throw new Error("No docs");
    await set(KEY, JSON.stringify(data.filter((c) => c.id !== id)));
    await queryClient.invalidateQueries([KEY]);
  });
};

export const useUpsertDoc = () => {
  const { refetch } = useDocSets();
  const queryClient = useQueryClient();
  const { set } = useLocalStorage();
  return useMutation(
    async (o: DocSet) => {
      let { data } = await refetch();
      if (!data) data = [];
      data = data.concat();
      const existingIndex = data.findIndex((c) => c.id === o.id);
      if (existingIndex === -1) data.push(o);
      else data[existingIndex] = o;
      await set(KEY, JSON.stringify(data));
      return o;
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries([KEY]);
      },
    }
  );
};

export const useDocAiRequest = () => {
  const { mutateAsync: createAiRequest } = useCreateAiRequestAsyncMutation();
  const { get } = useSettings();
  return useMutation(async (docSet: DocSet) => {
    const settings = await get();
    const result = await createAiRequest({
      input: {
        accountId: settings.accountId,
        type:
          docSet.type === "facts" ? AiRequestType.Facts : AiRequestType.Summary,
        dataSource: {
          type: DataSourceType.Array,
          array: docSet.docs.map<DataSourceInput>((doc) =>
            isUrl(doc)
              ? { type: DataSourceType.Url, url: doc }
              : { type: DataSourceType.Text, textContent: doc }
          ),
        },
        prompt: docSet.prompt
          ? { type: DataSourceType.Text, textContent: docSet.prompt }
          : undefined,
        maxCharacters: docSet.maxCharacters,
        model: AiModel.Gpt_3_5Turbo_16k,
      },
    });
    return result.createAiRequestAsync.id;
  });
};

export const useGoogleSearch = () => {
  const { mutateAsync: createAiRequest } = useCreateAiRequestMutation();
  const { mutateAsync: deleteAiRequest } = useDeleteAiRequestMutation();

  const { get } = useSettings();
  return useMutation(async (search: string) => {
    const settings = await get();
    const accountId = settings.accountId;
    const result = await createAiRequest({
      input: {
        accountId,
        type: AiRequestType.DataSourcePrompt,
        prompt: {
          type: DataSourceType.Text,
          textContent: `
The current date and time is ${moment().format("lll")}

Answer the following:
${search}
        `,
        },
        dataSource: {
          type: DataSourceType.GoogleSearch,
          dataSource: {
            type: DataSourceType.AiRequest,
            aiRequest: {
              type: AiRequestType.Prompt,
              prompt: {
                type: DataSourceType.Text,
                textContent: `
The current date and time is ${moment().format("lll")}

START_QUERY
${search}
END_QUERY

Write a google search that will provide the necessary information to satisfy the prompt.
Just return what I should search for. Do not surround it with quotations.
            `.trim(),
              },
              accountId,
              model: AiModel.Gpt_3_5Turbo,
            },
          },
        },
        model: AiModel.Gpt_3_5Turbo_16k,
      },
    });
    const aiRequest = await result.createAiRequest;
    if (settings.deleteRequests) await deleteAiRequest({ id: aiRequest.id });
    return aiRequest.response ?? "No result";
  });
};
